import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Button, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import IncomeCompleteTable from "../../../components/DriverMobile/IncomeCompleteTable";
import PopUp from '../../../components/T4A/Popup';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 315,
        marginTop: 22,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    title: {
        color: '#101820',
        fontSize: 24,
        fontWeight: 'bold',
        letterSpacing: 0
    },
    dateDownload: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 44
    }
}))

function IncomeStatement() {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation('incomeOverview')

    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(0);
    const [style, setStyle] = useState(2);
    const [popupOpen, setPopupOpen] = useState(false);

    function clickPopupOpen() {
        setStyle(2);
        setPopupOpen(true);
    }
    function handlePopupClose() {
        setPopupOpen(false);
    }

    useEffect(() => {
        setStart(window.localStorage.getItem('current-start'));
        setEnd(window.localStorage.getItem('current-end'));
        setStartTime(window.localStorage.getItem('current-start-time'));
        setEndTime(window.localStorage.getItem('current-end-time'));
    }, [window.localStorage])

    return (
        <div className={classes.root}>
            <div onClick={() => history.goBack()}>
                <KeyboardBackspaceIcon fontSize='large' />
            </div>
            <div className={classes.dateDownload}>
                <div className={classes.title}>
                    {start} - {end}
                </div>
                <div>
                    <Button
                        style={{
                            backgroundColor: 'white',
                            height: 25,
                            border: '2px solid rgba(16,24,32,0.3)',
                            borderRadius: 12.5,
                            color: '#101820',
                            fontSize: 12,
                            fontWeight: 'bold',
                            letterSpacing: 0,
                            textAlign: 'center',
                            paddingTop: 6,
                            paddingBottom: 6,
                            paddingLeft: 9,
                            paddingRight: 9
                        }}
                    >
                        <span
                            style={{ textAlign: 'center' }}
                            onClick={() => {
                                clickPopupOpen(2)
                            }}
                        >
                            {t('download')}
                        </span>
                    </Button>
                </div>
            </div>
            <div>
                <IncomeCompleteTable type='income-statement' startTime={startTime} endTime={endTime} />
            </div>
            <PopUp style={style} open={popupOpen} handlePopupClose={handlePopupClose} type='income' timeStart={window.localStorage.getItem('current-start-time')} timeEnd={window.localStorage.getItem('current-end-time')} />
        </div>
    )
}
export default IncomeStatement;