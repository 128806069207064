import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Button, InputBase, makeStyles, MenuItem, Select, withStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import IncomeDeliveryStatements from '../../../components/DriverMobile/IncomeDeliveryStatements';
import IncomeAreaStatements from '../../../components/DriverMobile/IncomeAreaStatements';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 315,
        marginTop: 22,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    title: {
        color: '#101820',
        fontSize: 24,
        fontWeight: 'bold',
        letterSpacing: 0,
        marginTop: 44
    },
    selectedMenuItem: {
        backgroundColor: '#FFE5CD !important'
    },
    selectPeriod: {
        marginTop: 13,
        width: 290
    },
}))
const BootstrapInput = withStyles((theme) => ({
    root: {
        '& .MuiInputBase-input': {
            height: 23,
            width: 290,
            color: '#F68D2E',
            fontSize: 16,
            fontWeight: 500,
            letterSpacing: 0
        },
        '& .MuiSelect-icon': {
            color: '#F68D2E'
        }
    },
    input: {
        height: 44,
        width: 290,
        borderRadius: 7,
        backgroundColor: '#FFE5CD',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        paddingLeft: 13.5,
        paddingTop: 12,
        paddingRight: 88.5,
        paddingBottom: 10,
        '&:focus': {
            borderRadius: 4,
            boxShadow: '0 0 10px #fff'
        },
    }
}))(InputBase);

function DeliveryStatements() {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation('incomeOverview')

    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(0);
    const [filterType, setFilterType] = useState(0);

    useEffect(() => {
        if (process.env.REACT_APP_COUNTRY === 'CA') {
            if (window.localStorage.getItem('period') === '0') {
                setStart(window.localStorage.getItem('current-start-now'));
                setEnd(window.localStorage.getItem('current-end-now'))
                setStartTime(window.localStorage.getItem('current-start-time-now'));
                setEndTime(window.localStorage.getItem('current-end-time-now'))
            } else {
                setStart(window.localStorage.getItem('current-start'));
                setEnd(window.localStorage.getItem('current-end'))
                setStartTime(window.localStorage.getItem('current-start-time'));
                setEndTime(window.localStorage.getItem('current-end-time'))
            }
        } else {
            setStart(window.localStorage.getItem('current-start-end'))
        }
    }, [window.localStorage])

    return (
        <div className={classes.root}>
            <div onClick={() => history.goBack()}>
                <KeyboardBackspaceIcon fontSize='large' />
            </div>
            {
                process.env.REACT_APP_COUNTRY === 'US' &&
                <div className={classes.title}>
                    {start}
                </div>
            }
            {
                process.env.REACT_APP_COUNTRY === 'CA' &&
                <div className={classes.title}>
                    {start} - {end}
                </div>
            }
            {
                process.env.REACT_APP_COUNTRY === 'CA' &&
                <div className={classes.selectPeriod}>
                    <Select
                        fullWidth
                        value={filterType}
                        onChange={(e) => setFilterType(e.target.value)}
                        input={<BootstrapInput />}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                    >
                        <MenuItem
                            value={0}
                            classes={{ selected: classes.selectedMenuItem }}
                            style={{
                                height: 44,
                                width: 270,
                                borderRadius: 7,
                                margin: 10
                            }}
                        >
                            {t('view_deliveries_by_date')}
                        </MenuItem>
                        <MenuItem
                            value={1}
                            classes={{ selected: classes.selectedMenuItem }}
                            style={{
                                height: 44,
                                width: 270,
                                borderRadius: 7,
                                margin: 10
                            }}
                        >
                            {t('view_deliveries_by_area')}
                        </MenuItem>
                    </Select>
                </div>
            }
            {
                filterType === 0 ?
                    <div style={{ marginTop: 21 }}>
                        <IncomeDeliveryStatements startTime={startTime} endTime={endTime} />
                    </div>
                    :
                    <div style={{ marginTop: 21 }}>
                        <IncomeAreaStatements startTime={startTime} endTime={endTime} />
                    </div>
            }
        </div>
    )
}
export default DeliveryStatements;