import { createMuiTheme } from '@material-ui/core/styles';
import * as Color from '@material-ui/core/colors';

const darkTheme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: Color.orange[500]
        },
        secondary: {
            main: Color.deepOrange[900]
        }
    },
    overrides: {
        MuiInputBase: {
          input: {
            '&:-webkit-autofill': {
              transitionDelay: '9999s',
              transitionProperty: 'background-color, color',
            },
          },
        },
      },
})

export default darkTheme