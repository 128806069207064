import {useState} from 'react';
import { useSelector } from 'react-redux';
import {ThemeProvider} from '@material-ui/core/styles';

import {currentTheme} from './reducers/uiSlice';
import darkTheme from './themes/darkTheme';
import lightTheme from './themes/lightTheme';
import UniSnackbarAlert from './components/UniSnackbarAlert';
import {
  BrowserRouter as Router, Route,
} from "react-router-dom";

import { renderRoutes } from 'react-router-config'
import {routes} from "./route/index";
import {routesMobile} from "./routeDriverMobile/index";
import AlertContextProvider from './components/UniAlert/context';
import UniAlert from './components/UniAlert';
import { isMobile } from 'react-device-detect';

function App() {
  const isDarkTheme = useSelector(currentTheme);
  const theme = isDarkTheme ? darkTheme: lightTheme;

  return (
	<ThemeProvider theme={theme}>
		<AlertContextProvider>
			<Router>
				{renderRoutes(routes)}
				{/* {
					isMobile ?
					renderRoutes(routesMobile)
					:
					renderRoutes(routes)
				} */}
			</Router>
			<UniSnackbarAlert />
			<UniAlert />
		</AlertContextProvider>	
	</ThemeProvider>
  );
}

export default App;
