import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import HelpIcon from '@mui/icons-material/Help';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import { AlertTitle } from '@mui/material';
import { Dialog, DialogTitle, Snackbar, DialogContent, Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { CSVLink } from "react-csv";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
        borderBottom: 'unset',
        },
    },
    download: { 
        textDecoration: 'none', color: '#212121'
    },
    margin: {
        marginLeft: 5
    }
});

function Row(props) {
    const { row, index, columns } = props;
    const classes = useRowStyles();
    const {t} = useTranslation('common');

    const moment = require('moment');

    const [open, setOpen] = useState(false);
    const [tnosDisplay, setTnosDisplay] = useState([]);
    const [tnos, setTnos] = useState([]);
    const [rowOpen, setRowOpen] = useState(false);
    const [descroptionOpen, setDescriptionOpen] = useState(false);
    const [description, setDescription] = useState('');

    const handleClickOpen = () => {
        setRowOpen(true);
    };
    const handleClose = () => {
        setRowOpen(false);
    };
    const handleDescriptionClose = () => {
        setDescriptionOpen(false);
    }

    function clickDescriptionOpen(num) {
        if(num === 0) {
            setDescription(t('orders_des'));
            setDescriptionOpen(true);
        } else if(num === 1) {
            setDescription(t('completed_des'));
            setDescriptionOpen(true);
        } else if(num === 2) {
            setDescription(t('transferred_des'));
            setDescriptionOpen(true);
        } else if(num === 3) {
            setDescription(t('pending_des'));
            setDescriptionOpen(true);
        } else if(num === 4) {
            setDescription(t('settled_des'));
            setDescriptionOpen(true);
        } else if(num === 5) {
            setDescription(t('to_be_settled_des'));
            setDescriptionOpen(true);
        }
    }
    function displayOrders(tnos) {
        if(tnos.length > 0) {
            setTnos(tnos);
            handleClickOpen();
        }
    }

    useEffect(() => { 
        if(tnos) {
            let tnosCopy = tnos;
            tnosCopy.map((tno) => {
                if(tno.time) {
                    tno.time_display = moment(tno.time*1000).format('MM-DD HH:mm');
                } else {
                    tno.time_display = moment(tno.update_time*1000).format('MM-DD HH:mm');
                }
            })
            setTnosDisplay(tnosCopy);
        }
    }, [tnos])

  return (
    <React.Fragment>
        <Dialog  fullWidth maxWidth='md' onClose={handleClose} aria-labelledby="customized-dialog-title" open={rowOpen}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {t('tnos')}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <TableContainer>
                            <div style={{ height: 500, width: '100%' }}>
                                <DataGrid
                                    rows={tnosDisplay}
                                    columns={columns}
                                    pageSize={8}
                                    rowsPerPageOptions={[5]}
                                    disableSelectionOnClick
                                />
                            </div>
                        </TableContainer>
                    </Typography>
                </DialogContent>
            </Dialog>
        <Snackbar open={descroptionOpen} autoHideDuration={6000} onClose={handleDescriptionClose}>
            <Alert variant="filled" severity="info" onClose={handleDescriptionClose}>
                {description}
            </Alert>
        </Snackbar>
        <TableRow className={classes.root} onClick={() => setOpen(!open)}>
            <TableCell>
                <IconButton aria-label="expand row" size="small">
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
                {row.date}
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                        <Table size="small" aria-label="purchases">
                            {/* <TableRow>
                                <TableCell align="left" onClick={()=>clickDescriptionOpen(0)}>
                                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('orders')}</div>
                                        <HelpIcon
                                            style={{ width: 16 }}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell align="left" onClick={()=>{displayOrders(row.c1)}}>
                                    {row.c1.length ? row.c1.length : ''}
                                </TableCell>
                                <Button 
                                    onClick={()=>{displayOrders(row.c1)}}
                                    disabled={!(row.c1.length > 0)}
                                    color="secondary"
                                >   
                                    {t('view')}
                                </Button>
                                { index === 0 &&
                                <Button color="secondary" style={{marginLeft: 5}} disabled={!(row.c1.length > 0)}>
                                    <CSVLink 
                                        data={ row.c1 || [] }
                                        style={{textDecoration: 'none', color: '#212121'}}
                                    >
                                        {t('download_excel')}
                                    </CSVLink>
                                </Button>
                                }
                            </TableRow> */}
                            {/* <TableRow>
                                <TableCell align="left" onClick={()=>clickDescriptionOpen(4)}>
                                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('settled')}</div>
                                        <HelpIcon
                                            style={{ width: 16 }}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell align="left" onClick={()=>{displayOrders(row.c5)}}>
                                    {row.c5.length ? row.c5.length : ''}
                                </TableCell>
                                <Button 
                                    onClick={()=>{displayOrders(row.c5)}}
                                    disabled={!(row.c5.length > 0)}
                                    color="secondary"
                                >   
                                    {t('view')}
                                </Button>
                                { index === 0 &&
                                <Button color="secondary" style={{marginLeft: 5}} disabled={!(row.c5.length > 0)}>
                                    <CSVLink 
                                        data={ row.c5 || [] }
                                        style={{textDecoration: 'none', color: '#212121'}}
                                    >
                                        {t('download_excel')}
                                    </CSVLink>
                                </Button>
                                }
                            </TableRow> */}
                            {/* <TableRow>
                                <TableCell align="left" onClick={()=>clickDescriptionOpen(5)}>
                                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('to_be_settled')}</div>
                                        <HelpIcon
                                            style={{ width: 16 }}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell align="left" onClick={()=>{displayOrders(row.c6)}}>
                                    {row.c6.length ? row.c6.length : ''}
                                </TableCell>
                                <Button 
                                    onClick={()=>{displayOrders(row.c6)}}
                                    disabled={!(row.c6.length > 0)}
                                    color="secondary"
                                >   
                                    {t('view')}
                                </Button>
                                { index === 0 &&
                                <Button color="secondary" style={{marginLeft: 5}} disabled={!(row.c6.length > 0)}>
                                    <CSVLink 
                                        data={ row.c6 || [] }
                                        style={{textDecoration: 'none', color: '#212121'}}
                                    >
                                        {t('download_excel')}
                                    </CSVLink>
                                </Button>
                                }
                            </TableRow> */}
                            {/* <TableRow>
                                <TableCell align="left" onClick={()=>clickDescriptionOpen(3)}>
                                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('pending')}</div>
                                        <HelpIcon
                                            style={{ width: 16 }}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell align="left" onClick={()=>{displayOrders(row.c4)}}>
                                    {row.c4.length ? row.c4.length : ''}
                                </TableCell>
                                <Button 
                                    onClick={()=>{displayOrders(row.c4)}}
                                    disabled={!(row.c4.length > 0)}
                                    color="secondary"
                                >   
                                    {t('view')}
                                </Button>
                                { index === 0 &&
                                <Button color="secondary" style={{marginLeft: 5}} disabled={!(row.c4.length > 0)}>
                                    <CSVLink 
                                        data={ row.c4 || [] }
                                        style={{textDecoration: 'none', color: '#212121'}}
                                    >
                                        {t('download_excel')}
                                    </CSVLink>
                                </Button>
                                }
                            </TableRow> */}
                            <TableRow>
                                <TableCell align="left" onClick={()=>clickDescriptionOpen(1)}>                                
                                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('completed')}</div>
                                        <HelpIcon
                                            style={{ width: 16 }}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell 
                                    align="left" 
                                    onClick={()=>{displayOrders(row.c2)}}
                                >
                                    {row.c2.length ? row.c2.length : ''}
                                </TableCell>
                                <Button 
                                    onClick={()=>{displayOrders(row.c2)}}
                                    disabled={!(row.c2.length > 0)}
                                    color="secondary"
                                >   
                                    {t('view')}
                                </Button>
                                { index === 0 &&
                                <Button color="secondary" style={{marginLeft: 5}} disabled={!(row.c2.length > 0)}>
                                    <CSVLink 
                                        data={ row.c2 || [] }
                                        style={{textDecoration: 'none', color: '#212121'}}
                                    >
                                        {t('download_excel')}
                                    </CSVLink>
                                </Button>
                                }
                            </TableRow>
                            {/* <TableRow>
                                <TableCell align="left" onClick={()=>clickDescriptionOpen(2)}>
                                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('transferred')}</div>
                                        <HelpIcon
                                            style={{ width: 16 }}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell align="left" onClick={()=>{displayOrders(row.c3)}}>
                                    {row.c3.length ? row.c3.length : ''}
                                </TableCell>
                                <Button 
                                    onClick={()=>{displayOrders(row.c3)}}
                                    disabled={!(row.c3.length > 0)}
                                    color="secondary"
                                >   
                                    {t('view')}
                                </Button>
                                { index === 0 &&
                                <Button color="secondary" style={{marginLeft: 5}} disabled={!(row.c3.length > 0)}>
                                    <CSVLink 
                                        data={ row.c3 || [] }
                                        style={{textDecoration: 'none', color: '#212121'}}
                                    >
                                        {t('download_excel')}
                                    </CSVLink>
                                </Button>
                                }
                            </TableRow> */}
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </React.Fragment>
  );
}

export default function StatisticsTableMobile(props) {
    const {t} = useTranslation('common');

    const moment = require('moment');

    const [rows, setRows] = useState([]);
    const [driver, setDriver] = useState([]);
    const [tnos, setTnos] = useState([]);
    const [rowOpen, setRowOpen] = useState(false);
    const [tnosDisplay, setTnosDisplay] = useState([]);
    const [description, setDescription] = useState('');
    const [descriptionOpen, setDescriptionOpen] = useState(false);
    
    const handleClickOpen = () => {
        setRowOpen(true);
    };
    const handleClose = () => {
        setRowOpen(false);
    };
    const handleDescriptionClose = () => {
        setDescriptionOpen(false);
    }

    function clickDescriptionOpen(num) {
        if(num === 0) {
            setDescription(t('orders_des'));
            setDescriptionOpen(true);
        } else if(num === 1) {
            setDescription(t('completed_des'));
            setDescriptionOpen(true);
        } else if(num === 2) {
            setDescription(t('transferred_des'));
            setDescriptionOpen(true);
        } else if(num === 3) {
            setDescription(t('pending_des'));
            setDescriptionOpen(true);
        } else if(num === 4) {
            setDescription(t('settled_des'));
            setDescriptionOpen(true);
        } else if(num === 5) {
            setDescription(t('to_be_settled_des'));
            setDescriptionOpen(true);
        }
    }

    function displayOrders(tnos) {
        if(tnos.length > 0) {
            setTnos(tnos);
            handleClickOpen();
        }
    }

    useEffect(() => {
        if(props.driverData) {
            setRows(props.driverData);
        }
    }, [props.driverData])
    useEffect(() => {
        if(props.driverInfo) {
            if(props.driverInfo[0]) {
                setDriver(props.driverInfo[0].name);
            }
        }
    }, [props.driverInfo])
    useEffect(() => { 
        if(tnos) {
            let tnosCopy = tnos;
            tnosCopy.map((tno) => {
                if(tno.time) {
                    tno.time_display = moment(tno.time*1000).format('MM-DD HH:mm');
                } else {
                    tno.time_display = moment(tno.update_time*1000).format('MM-DD HH:mm');
                }
            })
            setTnosDisplay(tnosCopy);
        }
    }, [tnos])

    const columns = [
        { 
            field: 'time_display', 
            headerName: t('update_time'), 
            width: 110
        },
        { 
            field: 'pack_id', 
            headerName: t('pack_id'), 
            width: 110
        },
        {
          field: 'tno',
          headerName: t('tno'),
          width: 200
        },
        {
          field: 'pathInfo',
          headerName: t('pathInfo'),
          width: 300
        },
        {
          field: 'address1',
          headerName: t('address'),
          width: 400
        }
    ];

    return (
        <div>
            <Dialog fullWidth maxWidth='lg' onClose={handleClose} aria-labelledby="customized-dialog-title" open={rowOpen}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {t('tnos')}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <TableContainer>
                            <div style={{ height: 500, width: '100%' }}>
                                <DataGrid
                                    rows={tnosDisplay}
                                    columns={columns}
                                    pageSize={8}
                                    rowsPerPageOptions={[5]}
                                    disableSelectionOnClick
                                />
                            </div>
                        </TableContainer>
                    </Typography>
                </DialogContent>
            </Dialog>
            <Snackbar open={descriptionOpen} autoHideDuration={6000} onClose={handleDescriptionClose}>
                <Alert variant="filled" severity="info" onClose={handleDescriptionClose}>
                    {description}
                </Alert>
            </Snackbar>
            <div hidden={!props.identity}>
                {
                    driver &&
                    <div
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop: 20,
                            width: 'fit-content',
                            padding: 10,
                            backgroundColor: '#9CCC65',
                            border: '1px solid #558B2F',
                            borderRadius: 5
                        }}
                    >{t('driver')}{driver}</div>
                }
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell align='left'>{t('date')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {/* {rows.map((row, index) => ( */}
                        {
                            rows?.length > 0 &&
                            <Row key={rows[0].name} row={rows[0]} index={0} columns={columns}/>
                        }
                        {/* ))} */}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div hidden={props.identity}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>
                                {t('date')}
                            </TableCell>
                            {/* <TableCell 
                                align="center" 
                                onClick={()=>clickDescriptionOpen(0)}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('orders')}</div>
                                    <HelpIcon
                                        style={{ width: 16 }}
                                    />
                                </div>
                            </TableCell> */}
                            {/* <TableCell 
                                align="center" 
                                onClick={()=>clickDescriptionOpen(3)}
                            >                                
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('pending')}</div>
                                    <HelpIcon
                                        style={{ width: 16 }}
                                    />
                                </div>
                            </TableCell> */}
                            <TableCell 
                                align="center" 
                                onClick={()=>clickDescriptionOpen(1)}
                            >                                
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('completed')}</div>
                                    <HelpIcon
                                        style={{ width: 16 }}
                                    />
                                </div>
                            </TableCell>
                            {/* <TableCell 
                                align="center" 
                                onClick={()=>clickDescriptionOpen(2)}
                            >                                
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('transferred')}</div>
                                    <HelpIcon
                                        style={{ width: 16 }}
                                    />
                                </div>
                            </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        rows.map((row, index) => (
                            <TableRow>
                                <TableCell 
                                    align="center"
                                >
                                    {row.date}
                                </TableCell>
                                {/* <TableCell 
                                    align="center" 
                                    onClick={()=>{displayOrders(row.c1)}}
                                >
                                    <div style={{
                                        display:'flex', flexDirection:'column',
                                        alignItems: 'center'
                                    }}>
                                        {row.c1.length ? row.c1.length : ''}
                                        <Button
                                            onClick={()=>{displayOrders(row.c1)}}
                                            disabled={!(row.c1.length > 0)}
                                            color="secondary"
                                        >
                                            {t('view')}
                                        </Button>
                                        {
                                            index === 0
                                            &&
                                            <Button color="secondary" style={{marginLeft: 5}}  disabled={!(row.c1.length > 0)}>
                                                <CSVLink 
                                                    data={ row.c1 || [] }
                                                    style={{textDecoration: 'none', color: '#212121'}}
                                                >
                                                    {t('download_excel')}
                                                </CSVLink>
                                            </Button>
                                        }
                                    </div>
                                </TableCell> */}
                                {/* <TableCell 
                                    align="center" 
                                    onClick={()=>{displayOrders(row.c4)}}
                                >
                                    <div style={{
                                        display:'flex', flexDirection:'column',
                                        alignItems: 'center'
                                    }}>
                                        {row.c4.length ? row.c4.length : ''}
                                        <Button
                                            onClick={()=>{displayOrders(row.c4)}}
                                            disabled={!(row.c4.length > 0)}
                                            color="secondary"
                                        >
                                            {t('view')}
                                        </Button>
                                        {
                                            index === 0
                                            &&
                                            <Button color="secondary" style={{marginLeft: 5}}  disabled={!(row.c4.length > 0)}>
                                                <CSVLink 
                                                    data={ row.c4 || [] }
                                                    style={{textDecoration: 'none', color: '#212121'}}
                                                >
                                                    {t('download_excel')}
                                                </CSVLink>
                                            </Button>
                                        }
                                    </div>
                                </TableCell> */}
                                <TableCell 
                                    align="center" 
                                    onClick={()=>{displayOrders(row.c2)}}
                                >
                                    <div style={{
                                        display:'flex', flexDirection:'column', alignItems: 'center'
                                    }}>
                                        {row.c2.length ? row.c2.length : ''}
                                        <Button
                                            onClick={()=>{displayOrders(row.c2)}}
                                            disabled={!(row.c2.length > 0)}
                                            color="secondary"
                                        >
                                            {t('view')}
                                        </Button>
                                        {
                                            index === 0
                                            &&
                                            <Button color="secondary" style={{marginLeft: 5}}  disabled={!(row.c2.length > 0)}>
                                                <CSVLink 
                                                    data={ row.c2 || [] }
                                                    style={{textDecoration: 'none', color: '#212121'}}
                                                >
                                                    {t('download_excel')}
                                                </CSVLink>
                                            </Button>
                                        }
                                    </div>
                                </TableCell>
                                {/* <TableCell 
                                    align="center" 
                                    onClick={()=>{displayOrders(row.c3)}}
                                >
                                    <div style={{
                                        display:'flex', flexDirection:'column', alignItems: 'center'
                                    }}>
                                        {row.c3.length ? row.c3.length : ''}
                                        <Button
                                            onClick={()=>{displayOrders(row.c3)}}
                                            disabled={!(row.c3.length > 0)}
                                            color="secondary"
                                        >
                                            {t('view')}
                                        </Button>
                                        {
                                            index === 0
                                            &&
                                            <Button color="secondary" style={{marginLeft: 5}}  disabled={!(row.c3.length > 0)}>
                                                <CSVLink 
                                                    data={ row.c3 || [] }
                                                    style={{textDecoration: 'none', color: '#212121'}}
                                                >
                                                    {t('download_excel')}
                                                </CSVLink>
                                            </Button>
                                        }
                                    </div>
                                </TableCell> */}
                            </TableRow>
                        ))
                    }
                    </TableBody>
                </Table>
            </div>
        </div>
    );
}