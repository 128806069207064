import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';
import { Button, DialogContent, DialogTitle, TableBody, TableCell, TableContainer, TableHead, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Dialog } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { CSVLink } from 'react-csv';

const StyledTableCellTitle = withStyles((theme) => ({
    head: {
      backgroundColor: "#4FC3F7"
    },
}))(TableCell);
const StyledTableCell = withStyles((theme) => ({
    body: {
      backgroundColor: "#E1F5FE"
    },
}))(TableCell);

export default function SalaryTable(props) {
    const { salaryInfo, identity, driverInfo, salarylist } = props;
    const {t} = useTranslation('common');

    const columns_ca = [
        { 
            field: 'id', 
            headerName: t('order_sn'), 
            width: 200
        },
        { 
            field: 'amount', 
            headerName: t('price'), 
            width: 150
        },
        { 
            field: 'zip', 
            headerName: t('zip'), 
            width: 150
        },
        { 
            field: 'partner', 
            headerName: t('partner'), 
            width: 150
        },
        {
            field: 'issecond', 
            headerName: t('is_second'), 
            width: 200, 
            headerAlign: 'center',
            valueFormatter: (params)=>params.value===1?t('Yes'):'',
            align: 'center'
        }
    ]
    const columns_us = [
        { 
            field: 'id', 
            headerName: 'ID', 
            width: 200
        },
        { 
            field: 'create time', 
            headerName: t('create_time'), 
            width: 200
        },
        { 
            field: 'm', 
            headerName: t('milage_m'), 
            width: 200
        },
        { 
            field: 'mi', 
            headerName: t('milage_mile'), 
            width: 200
        },
        { 
            field: 'amount', 
            headerName: t('amount_allowance'), 
            width: 200
        }
    ]
    const columns = process.env.REACT_APP_COUNTRY === 'US' ? columns_us : columns_ca;

    const [driver, setDriver] = useState('');
    const [rows, setRows] = useState([]);
    const [salaryList, setSalaryList] = useState([]);
    const [salaryListOpen, setSalaryListOpen] = useState(false);

    const handleSalaryListClose = () => {
        setSalaryListOpen(false);
    };

    function createRows(){
        return salaryList.map((row) => {
            const {tno, ...rest} = row
            return {id: tno, ...rest}
        })
    }

    useEffect(() => {
        if(salarylist) {
            if(salarylist.length > 0) {
                setSalaryList(salarylist);
            }
        }
    }, [salarylist])
    useEffect(() => {
        if(salaryInfo) {
            setRows(salaryInfo);
            console.log(salaryInfo)
        }
    }, [salaryInfo])
    useEffect(() => {
        if(driverInfo) {
            if(driverInfo[0]) {
                setDriver(driverInfo[0].name);
            }
        }
    }, [driverInfo])
    
  return (
        <>
            <Dialog fullWidth maxWidth='lg' onClose={handleSalaryListClose} aria-labelledby="customized-dialog-title" open={salaryListOpen}>
                <DialogTitle id="customized-dialog-title" onClose={handleSalaryListClose}>
                    {t('salary_list')}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <TableContainer>
                            <div style={{ height: 500, width: '100%' }}>
                                <DataGrid
                                    rows={createRows()}
                                    columns={process.env.REACT_APP_COUNTRY === 'US' ? columns_us : columns_ca}
                                    pageSize={8}
                                    rowsPerPageOptions={[5]}
                                    disableSelectionOnClick
                                />
                            </div>
                        </TableContainer>
                    </Typography>
                </DialogContent>
            </Dialog>
            <div hidden={!identity}>
                {
                    driver &&
                    <div
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop: 20,
                            width: 'fit-content',
                            padding: 10,
                            backgroundColor: '#9CCC65',
                            border: '1px solid #558B2F',
                            borderRadius: 5
                        }}
                    >{t('driver')}{driver}</div>
                }
            </div>
            <div 
                style={{
                    marginLeft: 'auto', 
                    marginRight: 'auto', 
                    maxWidth: 'fit-content',
                    marginTop: 100
                }}
            >
                <Table>
                    <TableHead>
                        {
                            process.env.REACT_APP_COUNTRY === 'US' ?
                            <TableRow>
                                <StyledTableCellTitle align='center'>{t('salary_amt')}</StyledTableCellTitle>
                                <StyledTableCellTitle align='center'>{t('order_ct')}</StyledTableCellTitle>
                                <StyledTableCellTitle align='center'>{t('work_hours')}</StyledTableCellTitle>
                                <StyledTableCellTitle align='center'>{t('order_rate')}</StyledTableCellTitle>
                                <StyledTableCellTitle align='center'>{t('t_salary')}</StyledTableCellTitle>
                                <StyledTableCellTitle align='center'>{t('mileage')}</StyledTableCellTitle>
                                <StyledTableCellTitle align='center'>{t('m_salary')}</StyledTableCellTitle>
                                <StyledTableCellTitle align='center'>{t('bonus')}</StyledTableCellTitle>
                            </TableRow>
                            :
                            <TableRow>
                            <StyledTableCellTitle align='center'>{t('amount')}</StyledTableCellTitle>
                            <StyledTableCellTitle align='center'>{t('orders_num')}</StyledTableCellTitle>
                            <StyledTableCellTitle align='center'>{t('delivered_num')}</StyledTableCellTitle>
                            <StyledTableCellTitle align='center'>{t('overweight_num')}</StyledTableCellTitle>
                            <StyledTableCellTitle align='center'>{t('overweight_amount')}</StyledTableCellTitle>
                            {/* <StyledTableCellTitle align='center'>{t('salary_type')}</StyledTableCellTitle> */}
                            <StyledTableCellTitle align='center'>{t('minus_add')}</StyledTableCellTitle>
                            <StyledTableCellTitle align='center'>{t('annual_income')}</StyledTableCellTitle>
                        </TableRow>
                        }
                    </TableHead>
                    <TableBody>
                        {
                            rows &&
                            process.env.REACT_APP_COUNTRY === 'US' ?
                            (
                                rows[0] &&
                                <TableRow>
                                    <StyledTableCell align='center'>{rows[0]['salary']}</StyledTableCell>
                                    <StyledTableCell 
                                        align='center'
                                        style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}
                                    >
                                        {rows[0]['order_ct']}
                                        <div style={{display: 'flex'}}>
                                            <Button 
                                                color='primary'
                                                disabled={salaryList.length <= 0}
                                                onClick={() => {
                                                    if(salaryList.length > 0) {
                                                        setSalaryListOpen(true);
                                                    }
                                                }}
                                            >
                                                {t('view')}
                                            </Button>
                                            <Button color="secondary" disabled={salaryList.length <= 0}>
                                                <CSVLink 
                                                    data={ salaryList || [] }
                                                    style={{textDecoration: 'none', color: '#212121'}}
                                                >
                                                    {t('download_excel')}
                                                </CSVLink>
                                            </Button>
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>{rows[0]['work_hours']}</StyledTableCell>
                                    <StyledTableCell align='center'>{rows[0]['order_rate']}</StyledTableCell>
                                    <StyledTableCell align='center'>{rows[0]['t_salary']}</StyledTableCell>
                                    <StyledTableCell align='center'>{rows[0]['mileage']}</StyledTableCell>
                                    <StyledTableCell align='center'>{rows[0]['m_salary']}</StyledTableCell>
                                    <StyledTableCell align='center'>{rows[0]['bonus']}</StyledTableCell>
                                </TableRow>
                            )
                            :
                            <TableRow>
                                <StyledTableCell align='center'>{rows['amt']}</StyledTableCell>
                                <StyledTableCell 
                                    align='center'
                                    style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}
                                >
                                    {rows['ct']}
                                    <div style={{display: 'flex'}}>
                                        <Button 
                                            color='primary'
                                            disabled={salaryList.length <= 0}
                                            onClick={() => {
                                                if(salaryList.length > 0) {
                                                    setSalaryListOpen(true);
                                                }
                                            }}
                                        >
                                            {t('view')}
                                        </Button>
                                        <Button color="secondary" disabled={salaryList.length <= 0}>
                                            <CSVLink 
                                                data={ salaryList || [] }
                                                style={{textDecoration: 'none', color: '#212121'}}
                                            >
                                                {t('download_excel')}
                                            </CSVLink>
                                        </Button>
                                    </div>
                                </StyledTableCell>
                                <StyledTableCell align='center'>{rows['203ct']}</StyledTableCell>
                                <StyledTableCell align='center'>{rows['ow_ct']}</StyledTableCell>
                                <StyledTableCell align='center'>{rows['ow_amt']}</StyledTableCell>
                                <StyledTableCell align='center'>{rows['amt_adj']}</StyledTableCell>
                                <StyledTableCell align='center'>{rows['cy_amt']}</StyledTableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </div>
        </>
    );
}
