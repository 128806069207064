import { Button, Dialog, DialogContent, DialogTitle, makeStyles, SvgIcon, TextField, withStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
// import { getOrderAndSalaryEmail } from '../../../actions/driverMobileActions';
import FullscreenLoading from '../../FullscreenLoading';
import { sendEmail } from '../../../actions/t4aActions';
import { showAlertSnackbar } from "../../../reducers/uiSlice";
import { getOrderAndSalaryEmail } from '../../../actions/driverMobileActions';

const DialogContentStyled = withStyles((theme) => ({
    root: {
        padding: '0 !important',
        minHeight: 467,
        maxWidth: 333,
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        margin: 0
    }
}))(DialogContent);
const DialogStyled = withStyles((theme) => ({
    root: {
        marginBottom: 150,
        padding: 0,
        minHeight: 467,
        width: 333,
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        marginLeft: 'auto',
        marginRight: 'auto',
        "& .MuiDialog-paper": {
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    }
}))(Dialog);
const FixedTextField = withStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-input': {
            padding: '10px !important',
        },
        width: 257,
        marginTop: 17
    },
}))(TextField);
const CloseIconStyled = withStyles((theme) => ({
    root: {
        fontSize: 34,
        color: '#F68D2E'
    },
}))(CloseIcon);

const useStyles = makeStyles((theme)=> ({
    headerBackground: {
        top: 0,
        height: 114,
        width: 333,
        opacity: 0.6,
        borderRadius: '4px 4px 0 0',
        background: 'linear-gradient(137.67deg, #FFE0C3 0%, #FFC086 100%)'
    },
    headerText: {
        position: 'absolute',
        top: 35,
        minWidth: '100%',
        color: '#101820',
        fontSize: 19,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    headerImg: {
        position: 'absolute',
        top: 82,
        marginLeft: 'auto', 
        marginRight: 'auto',
        width: '100%',
        textAlign: 'center'
    },
    imgSvg: {
        height: 80.62,
        width: 124
    },
    contentGroup: {
        marginTop: 66,
        width: '100%',
        textAlign: 'center'
    },
    contentDescription: {
        width: 272,
        color: '#101820',
        fontSize: 18,
        letterSpacing: 0,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#F68D2E !important'
    },
    checkboxGroup: {
        marginTop: 18,
        width: 259,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        alignItems: 'center'
    },
    checkboxDescription: {
        width: 231,
        color: '#101820',
        fontSize: 14,
        letterSpacing: 0
    },
    buttonGroup: {
        marginTop: 45,
        width: 257,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        justifyContent: 'space-between'
    },
    contentColored: {
        width: 246,
        color: '#F68D2E',
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: 0,
        textAlign: 'center',
        marginTop: 8,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    closeIcon: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 39,
        width: 36,
        height: 36,
        textAlign: 'center',
        verticalAlign: 'center',
        border: '1px #F68D2E solid',
        borderRadius: '50%'
    },
    contentHint: {
        marginTop: 7,
        width: 246,
        opacity: 0.8,
        color: '#101820',
        fontSize: 13,
        letterSpacing: 0,
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    errorMessage: {
        color: '#E91F1F',
        fontSize: 16,
        letterSpacing: 0,
        marginTop: 4
    }
}))

function PopUp(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [style, setStyle] = useState(0);
    const [saveChecked, setSaveChecked] = useState(true);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailHint, setEmailHint] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        } 
        setOpen(false);
        setOpenSuccess(false);
        props.handlePopupClose();
    };

    function handleEmailSend() {
        if(!email) {
            setEmailHint(true);
            return;
        }
        setEmailHint(false);
        setLoading(true);
        let save = 1;
        if(!saveChecked) {
            save = 0;
        }
        if(props.type === 'income') {
            dispatch(getOrderAndSalaryEmail(email, save, props.timeStart, props.timeEnd))
            .then((res) => {
                setLoading(false);
                setOpenSuccess(true);
                setStyle(3);
                setOpen(false);
                dispatch(showAlertSnackbar({message: 'Sent successfully! Please check you email!', type: 'success'}));
                handleClose();
            })
            .catch((err) => {
                setLoading(false);
                dispatch(showAlertSnackbar({message: 'Failed to send email! Please contact manager!', type: 'error'}))
            })
        } else if(props.type === 't4a') {
            dispatch(sendEmail(props.driverId, email))
            .then(() => {
                setLoading(false);
                dispatch(showAlertSnackbar({message: 'Sent successfully! Please check you email!', type: 'success'}));
                handleClose();
            })
            .catch(() => {
                setLoading(false);
                dispatch(showAlertSnackbar({message: 'Failed to send email! Please contact manager!', type: 'error'}))
            })
        }
    }

    useEffect(() => {
        setStyle(props.style)
    }, [props.style])
    useEffect(() => {
        setOpen(props.open);
        setEmailHint(false);
    }, [props.open])
    useEffect(() => {
        if(window.localStorage.getItem('email')) {
            setEmail(window.localStorage.getItem('email'));
        }
    }, [window.localStorage])

    return (      
        <DialogStyled
            open={open || openSuccess}
            onClose={handleClose}
        >
            <DialogContentStyled>
                <div>
                    <div>
                        <div 
                            className={classes.headerBackground}
                            style={
                                style === 1 ?
                                {height: 159}
                                :
                                (
                                    style === 2 ?
                                    {height: 114}
                                    :(
                                        style === 3 ?
                                        {
                                            height: 179, 
                                            background: 'linear-gradient(156.52deg, #D3D6D8 0%, #A8ACB0 100%)'
                                        } : {
                                            height: 160
                                        }
                                    ) 
                                )
                            }
                        ></div>
                        <div className={classes.headerText}>
                            {
                                style === 1 ? 
                                <div>Unpaid Orders</div>
                                :
                                (
                                    style === 2 ?
                                    <div>Update your email address</div> : 
                                    (
                                        style === 3 ?
                                        <div>Check your email</div> :
                                        <></>
                                    )
                                )
                            }
                        </div>
                        {
                            style === 1 ?
                            <div className={classes.headerImg} style={{top: '74px !important'}}>
                                <img 
                                    src='/images/dollar-bag.svg' 
                                    style={{
                                        height: 118,
                                        width: 109.14
                                    }}
                                />
                            </div>
                            :
                            (
                                style === 2 ?
                                <div className={classes.headerImg}>
                                    <img 
                                        src='/images/message.svg' 
                                        className={classes.imgSvg}
                                    />
                                </div>
                                :
                                <div className={classes.headerImg} style={{top: '87px !important'}}>
                                    <img 
                                        src='/images/email-sent.svg' 
                                        className={classes.imgSvg}
                                        style={{
                                            height: 123.06,
                                            width: 124 
                                        }}
                                    />
                                </div>
                            )
                        }
                    </div>
                    {
                        style === 1 ?
                        <div className={classes.contentGroup} style={{marginTop: 59}}>
                            <div className={classes.contentDescription}>Unpaid orders are the orders that you have completed but not paid in the currenr payment cycle.</div>
                            <div className={classes.contentColored}>
                                It will be paid out in the following payment cycle.
                            </div>
                        </div>
                        :
                        (
                            style === 2 ?
                            <div className={classes.contentGroup}>
                                <div className={classes.contentDescription}>Enter your email address, so we can send the statement to you.</div>
                                <div>
                                    <FixedTextField
                                        variant="outlined"
                                        InputProps={{
                                            classes: {
                                                notchedOutline: classes.notchedOutline,
                                            }
                                        }}
                                        value={email}
                                        onChange={(e)=>{
                                            setEmail(e.target.value);
                                        }}
                                    />
                                    {
                                        emailHint && 
                                        <div 
                                            className={classes.errorMessage}
                                        >
                                            Please input email address.
                                        </div>
                                    }
                                </div>
                            </div>
                            :
                            (
                                style === 3 ?
                                <div className={classes.contentGroup}>
                                    <div className={classes.contentDescription} style={{marginTop: 48}}>The document has been sent to the email <b>{email}</b></div>
                                    <div className={classes.contentHint}>
                                        You can change email address on your profile
                                    </div>
                                </div>
                                :
                                <div className={classes.contentGroup}>
                                    <div className={classes.contentDescription} style={{marginTop: 48}}>
                                        Enter your email address
                                    </div>
                                    <div className={classes.contentDescription}>
                                        Entrez votre adresse email
                                    </div>
                                    <FixedTextField
                                        variant="outlined"
                                        InputProps={{
                                            classes: {
                                                notchedOutline: classes.notchedOutline,
                                            }
                                        }}
                                        value={email}
                                        onChange={(e)=>{
                                            setEmail(e.target.value);
                                        }}
                                    />
                                </div>
                            )
                        )
                    }
                    {
                        style === 2 &&
                        <div className={classes.checkboxGroup}>
                            <Checkbox 
                                checked={saveChecked} 
                                color='black'
                                onChange={()=>{
                                    setSaveChecked(!saveChecked);
                                }}
                            />
                            <span className={classes.checkboxDescription}>Save the email address in my driver profile</span>
                        </div>
                    }
                    {
                        (style === 1 || style === 3) &&
                        <div className={classes.closeIcon} onClick={handleClose}>
                            <CloseIconStyled/>
                        </div>
                    }
                    {
                        (style === 2 || style === 4) &&
                        <div className={classes.buttonGroup}>
                            <Button 
                                style={{
                                    color:'#F68D2E'
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button 
                                variant="contained"
                                style={{
                                    color:'white',
                                    backgroundColor: '#F68D2E',
                                    borderRadius: '20px 20px 20px 20px',
                                    paddingLeft: 40,
                                    paddingRight: 40,
                                    paddingTop: 10,
                                    paddingBottom: 10
                                }}
                                onClick={handleEmailSend}
                            >
                                Send
                            </Button>
                        </div>
                    }
                </div>
            </DialogContentStyled>
            <FullscreenLoading open={loading}/>
        </DialogStyled>        
    )
}
export default PopUp;