import { createMuiTheme } from '@material-ui/core/styles';
import * as Color from '@material-ui/core/colors';

const lightTheme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: Color.lightBlue[500]
        },
        secondary: {
            main: Color.blue[500]
        }
    }
})

export default lightTheme