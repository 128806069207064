import { useEffect, useState } from 'react';
import { Button, makeStyles, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { logInUserMobile } from "../../../actions/driverMobileActions";
import { useAlert } from '../../../utilities/hooks';
import FullscreenLoading from '../../../components/FullscreenLoading';
import { driverLogin } from '../../../actions/t4aActions';
import { getCurrentPayment, getSettlementTimeList, logInDriverUS } from '../../../actions/driverMobileActionsUS';

const useStyles = makeStyles((theme) => ({
    loginContainer: {
        width: 285,
        marginTop: 150,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    loginTitle: {
        width: 285,
        color: '#101820',
        fontSize: 24,
        fontWeight: 'bold',
        letterSpacing: 0
    },
    loginDescription: {
        height: 42,
        width: 285,
        color: '#8A8B8A',
        fontSize: 16,
        letterSpacing: 0,
        marginTop: 10
    },
    loginParam: {
        height: 24,
        color: '#101820',
        fontSize: 18,
        letterSpacing: 0
    },
    loginInput: {
        height: 48,
        width: 285
    },
    loginButton: {
        height: 41,
        width: 251,
        borderRadius: 24.5,
        backgroundColor: '#F68D2E',
        color: 'white',
        marginTop: 107,
        '&:hover': {
            background: "#F68D2E",
        },
    },
    errorMessage: {
        color: '#E91F1F',
        fontSize: 16,
        letterSpacing: 0
    }
}))

function LoginDriverMobile() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const alertDialog = useAlert();
    const history = useHistory();

    window.localStorage.setItem('menu', 0);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [loginFail, setLoginFail] = useState(false);

    async function handleLogin() {
        try {
            setLoading(true);
            const usernameStr = username || document.getElementById("username").value;
            const passwordStr = password || document.getElementById("password").value;
            if (process.env.REACT_APP_COUNTRY === 'US') {
                const res = await dispatch(logInDriverUS(usernameStr, passwordStr));
                const currentDateRange = await dispatch(getSettlementTimeList(res.subjectId));
                await dispatch(getCurrentPayment(res.subjectId, currentDateRange[Object.keys(currentDateRange)[0]].range.start, currentDateRange[Object.keys(currentDateRange)[0]].range.end));
            } else {
                await dispatch(logInUserMobile(usernameStr, passwordStr));
                // Hide T4A as discussed
                // Not in use due to SIN not updated with new process
                // dispatch(driverLogin(usernameStr, passwordStr))
                // .then(() => {
                //     setLoading(false);
                //     window.localStorage.setItem('income-overview-open', true);
                //     history.push('/driverMobile/main');
                // })
                // .catch(() => {
                //     setLoading(false);
                //     setLoginFail(true);
                // })
            }
            await window.localStorage.setItem('income-overview-open', true);
            await history.push('/driverMobile/main');
        } catch (err) {
            setLoginFail(true);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoginFail(false);
    }, [])

    return (
        <div className={classes.loginContainer}>
            {
                process.env.REACT_APP_COUNTRY === 'US' &&
                <div>
                    <div className={classes.loginTitle}>Settlement Center</div>
                    <div className={classes.loginDescription}>Sign in to your driver account to check your settlement history</div>
                </div>
            }
            {
                process.env.REACT_APP_COUNTRY === 'CA' &&
                <div>
                    <div className={classes.loginTitle}>My Income</div>
                    <div className={classes.loginDescription}>Sign in to your driver account to check your income and payment history</div>
                </div>
            }
            <div className={classes.loginParam} style={{ marginTop: 33 }}>Mobile number/Username</div>
            <TextField
                id='username'
                variant="standard"
                className={classes.loginInput}
                onChange={(e) => setUsername(e.target.value)}
            />
            {
                process.env.REACT_APP_COUNTRY === 'US' &&
                <div className={classes.loginParam} style={{ marginTop: 29 }}>Password</div>
            }
            {
                process.env.REACT_APP_COUNTRY === 'CA' &&
                <div className={classes.loginParam} style={{ marginTop: 29 }}>SIN</div>
            }
            <TextField
                id='password'
                variant="standard"
                className={classes.loginInput}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
            />
            <div className={classes.errorMessage} hidden={!loginFail}>Invalid login information. Please check details and try again.</div>
            <div style={{
                textAlign: 'center'
            }}
            >
                <Button
                    className={classes.loginButton}
                    onClick={handleLogin}
                    disabled={loading}
                >
                    Sign in
                </Button>
            </div>
            <FullscreenLoading open={loading} />
        </div>
    )
}
export default LoginDriverMobile;