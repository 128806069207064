import React, {useEffect} from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, makeStyles } from "@material-ui/core";
import PropTypes from 'prop-types';

import DialogCloseableTitle from "../DialogCloseableTitle";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
      zIndex: theme.zIndex.modal - 1
    },
  }));

function UniDialog(props){
    const {title, message, children, open, 
        onSubmit, onClose, onFinish, openFunction, 
        cancelButtonText, finishButtonText, buttons,
        disableDefaultButtons,
         ...rest} = props
    const {t} = useTranslation('common');
    const classes = useStyles();

    useEffect(() => {
        if(open){
            openFunction()
        }
    }, [open])

    return (
        <Dialog className={classes.root} open={open} onClose={onClose} {...rest}>
            <DialogCloseableTitle onClose={onClose}>
                {title}
            </DialogCloseableTitle>
            <DialogContent>
                {message && message.length > 0 ?
                    <DialogContentText>
                        {message}
                    </DialogContentText>
                    :
                    null
                }
                {children}
            </DialogContent>
            {!disableDefaultButtons || buttons.length > 0 ?
                <DialogActions>
                {
                    buttons && buttons.length > 0 ?
                    buttons.map((button, index) => (
                        <Button key={index} color={button.color} type="submit" onClick={button.action}>
                            {button.text}
                        </Button>
                    ))
                    :
                    <>
                        <Button type="submit" onClick={onClose}>
                            {cancelButtonText || t('cancel')}
                        </Button>
                        <Button color='primary' onClick={handleSubmit}>
                            {finishButtonText || t('submit')}
                        </Button>
                    </>
                }
                </DialogActions>
                :
                null
            }
        </Dialog>
    )

    function handleSubmit(){
        onFinish()
        onClose()
    }
}

UniDialog.defaultProps = {
    openFunction: ()=>{},
    buttons: [],
    disableDefaultButtons: false
}

UniDialog.propTypes = {
    openFunction: PropTypes.func
}

export default React.memo(UniDialog)