import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../../actions/userActions';
import StatisticsTable from '../../components/StatisticsTable';
import FullscreenLoading from '../../components/FullscreenLoading';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import { Language } from '@material-ui/icons';
import { changeLanguagePref, getLanguageString } from '../../utilities/language';
import { useTranslation } from 'react-i18next';
import StatisticsTableMobile from '../../components/StatisticsTableMobile';
import { Grid, Paper } from '@material-ui/core';
import { getDriverlist, getLagetSalary, getLagetSalaryLA, getOrderCount, getSalary } from '../../actions/driverAction';
import SalaryTable from '../../components/SalaryTable';
import SalaryTableMobile from '../../components/SalaryTableMobile';
import DriverlistTable from '../../components/DriverlistTable';

const useStyles = makeStyles((theme)=> ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh'
    },
    optionsBar: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        right: 0,
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
        },
        color: '#1976d2'
    },
    headBackground: {
        height: 160,
        background: '#efc661',
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 100
    },
    headGroup: {
        display: "flex",
        alignItems: 'center',
        padding: 10,
        color: '#1976d2'
    },
    userInfo: {
        position: 'absolute',
        left: 0
    },
    titleStyle: {
        marginTop: 10,
        fontSize: 'large',
        marginLeft: 'auto', 
        marginRight: 'auto', 
        width: 'fit-content',
        color: '#1976d2'
    },
    selectStyle: {
        display: 'flex',
        marginTop: 20,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 120
    },
    footBackground: {
        height: 60,
        background: '#efc661',
        position: 'fixed',
        bottom: 0,
        minWidth: '100%'        
    },
    footGroup: {
        display: 'flex',
        alignItems: 'space-around',
        width: '100%',
        height: '100%',
        alignItems: 'center'
    },
    tableStyle: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        paddingTop: 200,
        overflow: 'auto',
        left: 0,
        right: 0,
        paddingBottom: 60,
    },
    tagStyle: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        paddingTop: 160,
        overflow: 'auto',
        left: 0,
        right: 0,
        height: 40,
        zIndex: 10 // without zIndex, onClick won't work 
    },
    iconStyle: {
        width: '33%', 
        color: '#1976d2'
    },
    selectRightStyle: {
        float: 'right',
        top: 25,
        right: 20,
        width: 160
    },
    selectCenterStyle: {
        marginTop: 10,
        left: '50%',
        position: 'fixed',
        backgroundColor: 'white',
        zIndex: 1000
    }
}))

const FormControlPadding = withStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-input': {
            padding: '8px !important',
        }
    }
}))(FormControl);

function Main(){
    const classes = useStyles();
    const {t, i18n} = useTranslation('common');
    const dispatch = useDispatch();

    const [operation, setOperation] = useState('');
    const [switchPage, setSwitchPage] = useState(0);
    const [driverData, setDriverData] = useState([]);
    const [driverInfo, setDriverInfo] = useState([]);
    const [searchDriverOpen, setSearchDriverOpen] = useState(false);
    const [switchDriverId, setSwitchDriverId] = useState('');
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [enableSwitch, setEnableSwitch] = useState(false);
    const [identity, setIdentity] = useState(0); // admin = 1; driver = 0
    const [isMobile, setIsMobile] = useState(false);
    const [period, setPeriod] = useState(0);
    const [isSalary, setIsSalary] = useState(1);
    const [salaryInfo, setSalaryInfo] = useState({});
    const [salarylist, setSalarylist] = useState([]);
    const [driverlist, setDriverlist] = useState([]);

    const handleIsSalaryChange = (event) => {
        setIsSalary(event.target.value);
    };
    const handleOperationChange = (event) => {
        setOperation(event.target.value);
    };
    const handleSearchDriverClose = () => {
        setSearchDriverOpen(false);
        setOperation('');
    };

    function handleDriverId(e) {
        setSwitchDriverId(e.target.value);
        if(e.target.value) {
            setEnableSwitch(true);
        } else {
            setEnableSwitch(false);
        }
    }

    useEffect(() => {
        setSwitchPage(0);
        setSwitchDriverId('');
        setPeriod(0);
        if(localStorage.getItem('user_level') < 0) {
            setIdentity(0);
            if(window.localStorage.getItem("isMobile")==='true') {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        } else {
            if(window.localStorage.getItem("isMobile")==='true') {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
            setIdentity(1);
        }
    }, [localStorage])
    useEffect(() => {
        if(operation === 0) {
            userLogout();
        } else if(operation === 1) {
            setEnableSwitch(false);
            setSearchDriverOpen(true);
            setOperation('');
        } else if(operation === 2) {
            setLoadingOpen(true);
            dispatch(getDriverlist())
            .then((res) => {
                setDriverlist(res);
                setLoadingOpen(false);
            })
            .catch((err) => {
                console.log(err)
                setLoadingOpen(false);
            })
        }
    }, [operation])
    useEffect(() => {
        loadXMLDoc(period);
    }, [period])
    useEffect(() => {
        loadXMLDoc(period);
    }, [isSalary])

    return (
        <Box className={classes.root}>
            <Dialog onClose={handleSearchDriverClose} aria-labelledby="customized-dialog-title" open={searchDriverOpen}>
                <DialogTitle id="customized-dialog-title" onClose={handleSearchDriverClose}>
                    {t('search_driver')}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Driver ID"
                                fullWidth
                                onChange={(e)=>handleDriverId(e)}
                            />
                        </DialogContent>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>loadXMLDoc(period)} color="primary" disabled={!enableSwitch}>
                        {t('switch')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Box className={classes.headBackground}>
                <Box className={classes.headGroup}>
                    <Box>
                        {localStorage.getItem('username')}
                        <span hidden={localStorage.getItem('user_level') < 0}>[ADMIN]</span>
                    </Box>
                    <Box className={classes.optionsBar}>
                        <Button 
                            onClick={handleLanguageChange} 
                            startIcon={<Language/>}
                            style={{ color: '#1976d2' }}
                        >
                            {getLanguageString(i18n.language)}
                        </Button>
                    </Box>
                </Box>
                <Box className={classes.titleStyle}>
                    {t('title')}
                </Box>
                <div hidden={localStorage.getItem('user_level') < 0}>
                    <FormControlPadding variant="outlined" className={classes.selectStyle}>                    
                        <Select                       
                            value={operation}
                            onChange={handleOperationChange}
                            color='primary'
                            displayEmpty
                        >
                            <MenuItem value=''>{t('main_page')}</MenuItem>
                            <MenuItem value={1}>{t('switch_driver')}</MenuItem>
                            <MenuItem value={2}>{t('driver_list')}</MenuItem>
                            <MenuItem value={0}>{t('log_out')}</MenuItem>
                        </Select>                        
                    </FormControlPadding>
                </div>
                <div hidden={localStorage.getItem('user_level') >= 0}>
                    <FormControlPadding variant="outlined" className={classes.selectStyle}>                    
                        <Select                       
                            value={operation}
                            onChange={handleOperationChange}
                            color='primary'
                            displayEmpty
                        >
                            <MenuItem value=''>{t('main_page')}</MenuItem>
                            <MenuItem value={0}>{t('log_out')}</MenuItem>
                        </Select>
                    </FormControlPadding>
                </div>    
            </Box>
            <Box className={classes.tagStyle}>
                <div hidden={operation === 2}>
                    <Grid 
                        container
                    >
                        <Grid item xs={4} onClick={()=>{setPeriod(0)}}>
                            <Paper 
                                square
                                style={ period === 0 ? {
                                    background: '#ffffff',lineHeight: '40px', textAlign: 'center'
                                } : {
                                    background: '#efc661',lineHeight: '40px', textAlign: 'center'
                                }}
                            >
                                {t('current_period')}
                            </Paper>
                        </Grid>
                        <Grid item xs={4} onClick={()=>setPeriod(1)}>
                            <Paper 
                                square
                                style={ period === 1 ? {
                                    background: '#ffffff',lineHeight: '40px', textAlign: 'center'
                                } : {
                                    background: '#efc661',lineHeight: '40px', textAlign: 'center'
                                }}
                            >
                                {t('previous_period')}
                            </Paper>
                        </Grid>
                        <Grid item xs={4} onClick={()=>setPeriod(2)}>
                            <Paper 
                                square
                                style={ period === 2 ? {
                                    background: '#ffffff',lineHeight: '40px', textAlign: 'center'
                                } : {
                                    background: '#efc661',lineHeight: '40px', textAlign: 'center'
                                }}
                            >
                                {t('last_period')}
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </Box>
            <Box hidden={operation !== 2}>
                <DriverlistTable driverlist={driverlist}/>
            </Box>
            {
                operation !== 2 &&
                <div>
                    <Box className={classes.tableStyle} hidden={isMobile}>
                        <div>
                            <FormControlPadding variant="outlined" className={classes.selectRightStyle}>                    
                                <Select                       
                                    value={isSalary}
                                    onChange={handleIsSalaryChange}
                                    color='primary'
                                    displayEmpty
                                >
                                    <MenuItem value={1}>{t('orders_details')}</MenuItem>
                                    <MenuItem value={2}>{t('salary_details')}</MenuItem>
                                </Select>
                            </FormControlPadding>
                        </div>
                        <div 
                            hidden={!(period === 0 && isSalary === 2)}
                            style={{
                                textAlign: 'center',
                                marginTop: 100,
                                color: "#4FC3F7",
                                fontWeight: 700
                            }}
                        >
                            {t('no_salary_yet')}
                        </div>
                        <div hidden={isSalary !== 1}>
                            <StatisticsTable 
                                driverData={driverData} 
                                driverInfo={driverInfo} 
                                identity={identity} 
                                handleIsSalary={handleIsSalary}
                            />  
                        </div>
                        <div hidden={isSalary !== 2 || period === 0}>
                            <SalaryTable 
                                driverData={driverData} 
                                salaryInfo={salaryInfo}
                                salarylist={salarylist}
                                identity={identity} 
                                handleIsSalary={handleIsSalary}
                            />
                        </div>
                    </Box>
                    <Box className={classes.tableStyle} hidden={!isMobile}>
                        <div className={classes.selectCenterStyle}>
                            <FormControlPadding variant="outlined" style={{width: 160}}>                    
                                <Select                       
                                    value={isSalary}
                                    onChange={handleIsSalaryChange}
                                    color='primary'
                                    displayEmpty
                                >
                                    <MenuItem value={1}>{t('orders_details')}</MenuItem>
                                    <MenuItem value={2}>{t('salary_details')}</MenuItem>
                                </Select>
                            </FormControlPadding>
                        </div>
                        <div hidden={isSalary !== 1} style={{marginTop: 60}}>
                            <StatisticsTableMobile 
                                driverData={driverData} 
                                driverInfo={driverInfo}
                                identity={identity}
                                driverInfo={driverInfo}
                            />
                        </div>
                        <div hidden={isSalary !== 2} style={{marginTop: 60}}>
                            <SalaryTableMobile
                                driverData={driverData} 
                                salaryInfo={salaryInfo} 
                                salarylist={salarylist}
                                identity={identity} 
                                handleIsSalary={handleIsSalary}
                            />
                        </div>
                    </Box>
                </div>
            }
            <FullscreenLoading open={loadingOpen}/>
        </Box>        
    )

    function handleIsSalary(ifSalary) {
        setIsSalary(ifSalary);
    }
    function handleLanguageChange(){
        if(i18n.language === "en"){
            changeLanguagePref(i18n, "cn")
        } else {
            changeLanguagePref(i18n, "en")
        }
    }
    function loadXMLDoc(period) {
        setLoadingOpen(true);
        setSearchDriverOpen(false);
        let driverId = localStorage.getItem('id');
        if(switchDriverId) {
            driverId = switchDriverId;
        }
        if(isSalary === 1) {
            dispatch(getOrderCount(driverId, period))
            .then((res) => {
                setLoadingOpen(false);
                setDriverData(res.data);
                setDriverInfo(res.driverinfo);
                setSalaryInfo([]);
                setSalarylist([]);
            })
            .catch(() => {
                setDriverData([]);
                setDriverInfo([]);
                setLoadingOpen(false);
            })
        } else if(isSalary === 2) {
            if(process.env.REACT_APP_COUNTRY === 'US') {
                dispatch(getLagetSalaryLA(driverId, period))
                .then((res) => {
                    setLoadingOpen(false);
                    setDriverData([]);
                    setDriverInfo(res.driverinfo);
                    setSalaryInfo(res.salary);
                    setSalarylist(res.salarylist);
                })
                .catch((err) => {
                    setDriverData([]);
                    setDriverInfo([]);
                    setLoadingOpen(false);
                })
            } else {
                dispatch(getSalary(driverId, period))
                .then((res) => {
                    setLoadingOpen(false);
                    setDriverData([]);
                    setDriverInfo(res.driverinfo);
                    setSalaryInfo(res.salary);
                    setSalarylist(res.salarylist);
                })
                .catch((err) => {
                    setDriverData([]);
                    setDriverInfo([]);
                    setLoadingOpen(false);
                })
            }
        }
    }
}

export default Main