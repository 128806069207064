import { renderRoutes } from "react-router-config";
import { Redirect, Route } from "react-router-dom";

function ProtectedRouteDriverMobile({route}) {
    const token = window.localStorage.getItem("auth_token")
    return (
        <div>
            {token ? renderRoutes(route.routes) : <Redirect to='/driverMobile/login'/>}
        </div>
    )
}

export default ProtectedRouteDriverMobile