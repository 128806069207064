export const USER_LOGIN_MOBILE = 'USER_LOGIN_MOBILE';
export const USER_LOGIN_MOBILE_SUCCESS = "USER_LOGIN_MOBILE_SUCCESS";
export const USER_LOGIN_MOBILE_FAIL = "USER_LOGIN_MOBILE_FAIL";

// Income overview
export const GET_ORDER_AND_SALARY = 'GET_ORDER_AND_SALARY'
export const GET_ORDER_AND_SALARY_SUCCESS = 'GET_ORDER_AND_SALARY_SUCCESS'
export const GET_ORDER_AND_SALARY_FAIL = 'GET_ORDER_AND_SALARY_FAIL'

export const GET_ORDER_AND_SALARY_EMAIL = "GET_ORDER_AND_SALARY_EMAIL"
export const GET_ORDER_AND_SALARY_EMAIL_SUCCESS = "GET_ORDER_AND_SALARY_EMAIL_SUCCESS"
export const GET_ORDER_AND_SALARY_EMAIL_FAIL = "GET_ORDER_AND_SALARY_EMAIL_FAIL"

// Income delivery statements
export const GET_ORDER_AND_SALARY_DETAIL = 'GET_ORDER_AND_SALARY_DETAIL'
export const GET_ORDER_AND_SALARY_DETAIL_SUCCESS = 'GET_ORDER_AND_SALARY_DETAIL_SUCCESS'
export const GET_ORDER_AND_SALARY_DETAIL_FAIL = 'GET_ORDER_AND_SALARY_DETAIL_FAIL'

export const GET_ORDER_AND_SALARY_DETAIL_LA = 'GET_ORDER_AND_SALARY_DETAIL_LA'
export const GET_ORDER_AND_SALARY_DETAIL_LA_SUCCESS = 'GET_ORDER_AND_SALARY_DETAIL_LA_SUCCESS'
export const GET_ORDER_AND_SALARY_DETAIL_LA_FAIL = 'GET_ORDER_AND_SALARY_DETAIL_LA_FAIL'

export const GET_SALARY_ZIP_COUNT = "GET_SALARY_ZIP_COUNT"
export const GET_SALARY_ZIP_COUNT_SUCCESS = "GET_SALARY_ZIP_COUNT_SUCCESS"
export const  GET_SALARY_ZIP_COUNT_FAIL = "GET_SALARY_ZIP_COUNT_FAIL"