import { useEffect, useState } from "react";
import DownloadT4A from "../../../components/T4A/DownloadT4A";
import { useHistory } from "react-router-dom";

function DownloadT4APageWeb() {
    const history = useHistory();

    return (
        <div>
            <img 
                src='/images/group-copy.svg' 
                style={{
                    height: 42,
                    width: 178,
                    marginTop: 22,
                    marginLeft: 20
                }}
            />
            <DownloadT4A />
        </div>
    )
}
export default DownloadT4APageWeb;