import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import { getLanguagePref } from './utilities/language';
import commonEN from './i18n/en/common.json';
import commonCN from './i18n/cn/common.json';
import incomeOverviewEN from './i18n/en/incomeOverview.json';
import incomeOverviewCN from './i18n/cn/incomeOverview.json';
import incomeOverviewFR from './i18n/fr/incomeOverview.json';

const resources = {
    en: {
        common: commonEN,
        incomeOverview: incomeOverviewEN
    },
    cn:{
        common: commonCN,
        incomeOverview: incomeOverviewCN
    },
    fr:{
        incomeOverview: incomeOverviewFR
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en",
        lng: getLanguagePref(),
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;