import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: 1000,
      color: '#fff',
    },
  }));

function FullscreenLoading(props){

    const classes = useStyles()

    return (
        <Backdrop className={classes.backdrop} open={props.open}>
            <CircularProgress color='secondary'/>
        </Backdrop>
    )
}

FullscreenLoading.defaultProps = {
    open: false
}

export default FullscreenLoading