import { Button, makeStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { downloadPdf } from "../../../actions/t4aActions";
import PopUp from "../../T4A/Popup";
import FullscreenLoading from "../../FullscreenLoading";
import { showAlertSnackbar } from "../../../reducers/uiSlice";

const ButtonConfirm = withStyles((theme) => ({
    root: {
        padding: '8px 30px 10px 32px',
        borderRadius: 4,
        backgroundColor: '#f68d2e',
        '& .MuiButton-label': {
            fontSize: 14,
            fontWeight: '500',
            textAlign: 'center',
            color: '#101820'
        },
        '&:hover': {
            background: "#F68D2E",
        },
    }
}))(Button);
const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 17,
        fontWeight: 'bold',
        color: '#262626',
        marginTop: 34,
        marginLeft:20
    }
}))

function DownloadT4A() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [popupOpen, setPopupOpen] = useState(false);
    const [driverId, setDriverId] = useState('');
    const [key, setKey] = useState('342bfd24d00cbdd71fea');
    const [loading, setLoading] = useState(false);
    
    function handlePopupClose() {
        setPopupOpen(false);
    }
    function handleDownload() {
        setLoading(true);
        const axios = require('axios');
            const baseURL = process.env.REACT_APP_SJ_SERVER_URL;
            const token = window.localStorage.getItem('token');
            const getPDF = () => {
                return axios.get(`${baseURL}/account/drivert4apdf?driver_id=${driverId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                      },
                      responseType: 'arraybuffer'
                }).then((res) => {
                    setLoading(false);
                    var response = {};
                    try{
                        response = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(res.data)));
                    }catch(e){
                    }
                    if(response.status==="FAIL"){
                        dispatch(showAlertSnackbar({message: response.ret_msg, type: 'error'}))
                    }else{
                        const url = window.URL.createObjectURL(new Blob([res.data]
                        ,{type: "application/pdf"}))
                      var link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', 't4A_'+driverId+'.pdf');
                      document.body.appendChild(link);
                      link.click();
                      link.remove();
                    }
                });
            }
            getPDF();
    }

    useEffect(() => {
        if(window.localStorage.getItem('driver_id')) {
            setDriverId(window.localStorage.getItem('driver_id'));
        }
        if(!window.localStorage.getItem('token')) {
            history.push('/t4a-login');
        }
    }, [window.localStorage])

    return (
        <div>
            <div className={classes.title}>
                Download Your T4A / Téléchargez votre T4A
            </div>
            <img
                src='/images/bitmap-copy.png' 
                style={{
                    height: 280,
                    width: 380,
                    marginTop: 18,
                    marginLeft: 14
                }}
                onClick={handleDownload}
            />
            <div style={{marginLeft:'auto', marginRight: 'auto', width: '100%', textAlign: 'center', marginTop: 44}}>
                <ButtonConfirm onClick={handleDownload}>
                    Download / Télécharger
                </ButtonConfirm>
            </div>
            <div style={{marginLeft:'auto', marginRight: 'auto', width: '100%', textAlign: 'center', marginTop: 27}}>
                <ButtonConfirm
                    onClick={() => setPopupOpen(true)}
                >
                    Email / E-mail
                </ButtonConfirm>
            </div>
            <PopUp open={popupOpen} style={4} handlePopupClose={handlePopupClose} driverId={driverId} type='t4a'/>
            <FullscreenLoading open={loading}/>
        </div>
    )
}
export default DownloadT4A;