import React from 'react';
import {Redirect} from 'react-router-dom';
import Main from '../pages/Main';
import Login from '../pages/Login';
import LoginT4A from '../pages/T4A/Login';
import ModifyInfoT4A from '../pages/T4A/ModifyInfoT4APage';
import DownloadT4APageWeb from '../pages/T4A/DownloadT4APage';
import ProtectedRoute from './protectedRoute';
import LoginDriverMobile from '../pages/driverMobile/Login';
import IncomeStatements from '../pages/driverMobile/IncomeStatements';
import IncomeStatement from '../pages/driverMobile/IncomeStatement';
import DeliveryStatements from '../pages/driverMobile/DeliveryStatements';
import DownloadT4APage from '../pages/driverMobile/DownloadT4APage';
import MainDriverMobile from '../pages/driverMobile/Main';

export const routes = [
    {
        path:'/login',
        component: Login
    },
    {
        path:'/t4a-login',
        component: LoginT4A
    },
    {
        path: '/t4a-info',
        component: ModifyInfoT4A
    },
    {
        path: '/t4a-download',
        component: DownloadT4APageWeb
    },
    {
        path:'/driverMobile/login',
        component: LoginDriverMobile
    },
    {
        path: '/driverMobile/incomeStatements',
        component: IncomeStatements
    },
    {
        path: '/driverMobile/incomeStatement',
        component: IncomeStatement
    },
    {
        path: '/driverMobile/deliveryStatements',
        component: DeliveryStatements
    },
    {
        path: '/driverMobile/t4a-download',
        component: DownloadT4APage
    },
    {
        path: '/driverMobile/main',
        component: MainDriverMobile
    },
    {
        component: ProtectedRoute,
        routes: [
            {
                path: '/main',
                component: Main
            },
            {
                path: "/",
                exact: true,
                render: () => {
                    return <Redirect to={'/main'}></Redirect>
                }
            }         
        ]
    }
]