export const type = {
    USER_LOGIN: "USER_LOGIN",
    USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
    USER_LOGIN_FAIL: "USER_LOGIN_FAIL",

    GET_CURRENT_PAYMENT: "GET_CURRENT_PAYMENT",
    GET_CURRENT_PAYMENT_SUCCESS: "GET_CURRENT_PAYMENT_SUCCESS",
    GET_CURRENT_PAYMENT_FAIL: "GET_CURRENT_PAYMENT_FAIL",

    GET_SETTLEMENT_TIME_LIST: "GET_SETTLEMENT_TIME_LIST",
    GET_SETTLEMENT_TIME_LIST_SUCCESS: "GET_SETTLEMENT_TIME_LIST_SUCCESS",
    GET_SETTLEMENT_TIME_LIST_FAIL: "GET_SETTLEMENT_TIME_LIST_FAIL",

    HANDLE_DETAILS: "HANDLE_DETAILS",

    GET_CURRENT_PAYMENT_PERIOD: "GET_CURRENT_PAYMENT_PERIOD",
    GET_CURRENT_PAYMENT_PERIOD_FAIL: "GET_CURRENT_PAYMENT_PERIOD_FAIL",
    GET_CURRENT_PAYMENT_PERIOD_SUCCESS: "GET_CURRENT_PAYMENT_PERIOD_SUCCESS",

    GET_PAYMENT_PERIOD: "GET_PAYMENT_PERIOD",
    GET_PAYMENT_PERIOD_SUCCESS: "GET_PAYMENT_PERIOD_SUCCESS",
    GET_PAYMENT_PERIOD_FAIL: "GET_PAYMENT_PERIOD_FAIL"
}