import { Fragment, useEffect, useState } from "react";
import { Button, Divider, Drawer, InputBase, makeStyles, MenuItem, Select, TextField } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from "@material-ui/styles";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import { changeLanguagePref } from "../../../utilities/language";
import i18n from "../../../i18n";
import { userLogout } from '../../../actions/userActions';
import FullscreenLoading from "../../FullscreenLoading";
import { useDispatch, useSelector } from "react-redux";
import { getOrderAndSalary, getOrderAndSalaryDetail } from "../../../actions/driverMobileActions";
import IncomeCompleteTable from "../IncomeCompleteTable";
import PopUp from "../../T4A/Popup";
import { useHistory } from "react-router-dom";
import T4A from "../../T4A";
import { searchDriver } from "../../../actions/t4aActions";
import { showAlertSnackbar } from "../../../reducers/uiSlice";
import moment from "moment-timezone";
import { getCurrentPayment, getCurrentPaymentPeriod, handleDetails } from "../../../actions/driverMobileActionsUS";
import { sortKeys } from "../../../utilities/common";

const useStyles = makeStyles((theme)=> ({
    root: {
        width: 315,
        marginTop: 22,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    menuBurger: {
        height: 21.75,
        width: 29
    },
    overviewTitle: {
        height: 29,
        color: '#101820',
        fontSize: 24,
        fontWeight: 'bold',
        letterSpacing: 0,
        marginTop: 29
    },
    overViewUsername: {
        height: 21,
        color: '#101820',
        fontSize: 18,
        letterSpacing: 0,
        marginTop: 13
    },
    selectPeriod: {
        marginTop: 13,
        width: 313
    },
    selectedMenuItem: {
        backgroundColor: '#FFE5CD !important'
    },
    selectedMenuItemHistory: {
        backgroundColor: 'white !important'
    },
    overviewContent: {
        marginTop: 20,
        width: 313
    },
    overViewDate: {
        height: 21,
        opacity: 0.5,
        color: '#101820',
        fontSize: 18,
        fontWeight: 'bold',
        letterSpacing: 0
    },
    incomeTitle: {
        color: '#F68D2E',
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: 0
    },
    incomeAmount: {
        color: '#F68D2E',
        fontSize: 40,
        fontWeight: 600,
        letterSpacing: 0
    },
    overviewTable: {
        marginTop: 22,
        borderTop: '3px solid #0D1923'
    },
    overviewTableRow: {
        backgroundColor: 'rgba(235,236,239,0.8)',
        padding: 11
    },
    overviewTableDetail: {
        display: 'flex',
        justifyContent: 'space-between',
        width: 290,
        paddingBottom: 7
    },
    overviewTableDescription: {
        height: 36,
        width: 313,
        color: '#686868',
        fontSize: 12,
        letterSpacing: 0,
        marginTop: 10
    },
    viewDetails: {
        marginTop: 32,
        marginLeft: 160,
        display: 'flex',
        alignItems: 'center'
    },
    viewDetailsText: {
        width: 129,
        color: '#F68D2E',
        fontSize: 18,
        fontWeight: 500,
        letterSpacing: 0,
        textAlign: 'center'
    },
    drawerStyle: {
        height: '90vh',
        opacity: 0.9,
        backgroundColor: '#101820',
        width: 215,
        padding: 30
    },
    menuTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        letterSpacing: 0,
        marginTop: 40
    },
    languageTitle: {
        height: 21,
        width: 82,
        opacity: 0.2,
        color: '#FFFFFF',
        fontSize: 18,
        fontWeight: 500,
        letterSpacing: 0,
        marginTop: 400
    },
    languageSelected: {
        color: '#FFFFFF',
        fontSize: 16,
        fontWeight: 'bold',
        letterSpacing: 0,
        borderBottom: '1px solid #FFFFFF',
        width: 'fit-content',
        marginTop: 24
    },
    language: {
        color: '#FFFFFF',
        fontSize: 16,
        fontWeight: 'bold',
        letterSpacing: 0,
        marginTop: 24
    },
    dateDownload: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}))
const BootstrapInput = withStyles((theme) => ({
    root: {
        '& .MuiInputBase-input': {
            height: 23,
            width: 313,
            color: '#F68D2E',
            fontSize: 16,
            fontWeight: 500,
            letterSpacing: 0
        },
        '& .MuiSelect-icon': {
            color: '#F68D2E'
        }
    },
    input: {
        height: 44,
        width: 314,
        borderRadius: 7,
        backgroundColor: '#FFE5CD',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        paddingLeft: 13.5,
        paddingTop: 12,
        paddingRight: 88.5,
        paddingBottom: 10,
        '&:focus': {
            borderRadius: 4,
            boxShadow: '0 0 10px #fff'
        },
    }
}))(InputBase);

const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
const monthNum = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

function IncomeOverview(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation('incomeOverview');

    const currentDateRange = useSelector(state => state.driverUSReducer.getSettlementTimeList);
    const currentPayment = useSelector(state => state.driverUSReducer.getCurrentPayment);

    const [period, setPeriod] = useState(0);
    const [menuState, setMenuState] = useState(false);
    const [username, setUsername] = useState('');
    const [driverId, setDriverId] = useState('');
    const [subjectId, setSubjectId] = useState('');
    const [currentStart, setCurrentStart] = useState('');
    const [currentEnd, setCurrentEnd] = useState('');
    const [currentStartTime, setCurrentStartTime] = useState(0);
    const [currentEndTime, setCurrentEndTime] = useState(0);
    const [lastStart, setLastStart] = useState('');
    const [lastEnd, setLastEnd] = useState('');
    const [loading, setLoading] = useState(false);
    const [style, setStyle] = useState(2);
    const [popupOpen, setPopupOpen] = useState(false);
    const [completed, setCompleted] = useState();
    const [incompleted, setIncompleted] = useState();
    const [overweightOrders, setOverweightOrders] = useState();
    const [overweightAmount, setOverweightAmount] = useState();
    const [allowance, setAllowance] = useState(); 
    const [amount, setAmount] = useState();
    const [salaryInfo, setSalaryInfo] = useState({});
    const [menu, setMenu] = useState(window.localStorage.getItem('menu') || 0);
    const [t4aGenerated, setT4aGenerated] = useState(false);

    const handlePeriodChange = (event) => {
        setPeriod(event.target.value);
    };

    function toggleDrawer(open) {
        setMenuState(open);
    };
    function clickPopupOpen(style) {
        setStyle(style);
        setPopupOpen(true);
    }
    function handlePopupClose() {
        setPopupOpen(false);
    }
    function viewDetails() {
        if (process.env.REACT_APP_COUNTRY === 'CA') {
            window.localStorage.setItem('current-start-now', currentStart);
            window.localStorage.setItem('current-end-now', currentEnd);
            window.localStorage.setItem('current-start-time-now', currentStartTime);
            window.localStorage.setItem('current-end-time-now', currentEndTime);
            window.localStorage.setItem('menu', 0);
            history.push('/driverMobile/deliveryStatements');
        } else {
            viewCurrentDetailsUS();
        }
    }

    async function viewCurrentDetailsUS() {
        try {
            window.localStorage.setItem('current-start-end', Object.keys(currentDateRange)[0]);
            let promises = {};
            await Promise.all(currentDateRange[Object.keys(currentDateRange)[0]].days.map(async (day, key) => {
                await dispatch(getCurrentPaymentPeriod(subjectId, day.start, day.end))
                    .then((res) => {
                        promises[day.date] = res;
                    })
            }))
            await dispatch(handleDetails(sortKeys(promises)));
            await history.push('/driverMobile/deliveryStatements');
        } catch {
            dispatch(showAlertSnackbar({ message: t('contact_it'), type: 'error' }))
        } finally { }
    }

    useEffect(() => {
        window.localStorage.setItem('period', period);
        if(period === 2) {
            history.push('/driverMobile/incomeStatements');
            window.localStorage.setItem('menu', 0);
        }
    }, [period])
    useEffect(() => {
        if(window.localStorage.getItem('username')) {
            setUsername(window.localStorage.getItem('username'))
        }
    }, [window.localStorage.getItem('username')])
    useEffect(() => {
        if (window.localStorage.getItem('id')) {
            setDriverId(window.localStorage.getItem('id'))
        }
    }, [window.localStorage.getItem('id')])
    useEffect(() => {
        if (window.localStorage.getItem('subject_id')) {
            setSubjectId(window.localStorage.getItem('subject_id'))
        }
    }, [window.localStorage.getItem('subject_id')])
    useEffect(() => {
        if (process.env.REACT_APP_COUNTRY === 'CA') {
            const today = new Date();
            if (today && menu === 0) {
                let currentStartTime = 0;
                let currentEndTime = 0;
                let lastStartTime = 0;
                let lastEndTime = 0;
                let monthLastStartIndex = 0;
                let monthLastEndIndex = 0;
                let monthCurrentStartIndex = 0;
                let monthCurrentEndIndex = 0;
                let dateLastStartIndex = 0;
                let dateLastEndIndex = 0;
                let dateCurrentStartIndex = 0;
                let dateCurrentEndIndex = 0;
                let yearLastStartIndex = 0;
                let yearLastEndIndex = 0;
                let yearCurrentStartIndex = 0;
                let yearCurrentEndIndex = 0;
                let monthCurr = month[today.getMonth()];
                let monthLast = '';
                if (today.getMonth() - 1 < 0) {
                    monthLast = month[month.length - 1];
                } else {
                    monthLast = month[today.getMonth() - 1];
                }
                let currentEndInfo = monthCurr + ' ' + today.getDate();
                let currentStartInfo = monthCurr + ' ';
                let lastStartInfo = '';
                let lastEndInfo = '';
                if (Number(today.getDate() === 1)) {
                    currentStartInfo = monthLast + ' ' + '16';
                    lastStartInfo = monthLast + ' ' + '1';
                    lastEndInfo = monthLast + ' ' + '16';

                    monthCurrentEndIndex = today.getMonth();
                    dateCurrentEndIndex = today.getDate();
                    dateCurrentStartIndex = 16;
                    dateLastStartIndex = 1;
                    dateLastEndIndex = 16;
                    yearCurrentEndIndex = today.getFullYear();
                    if (today.getMonth() - 1 < 0) {
                        monthLastStartIndex = month.length - 1;
                        monthCurrentStartIndex = month.length - 1;
                        yearCurrentStartIndex = yearCurrentEndIndex - 1;
                        yearLastStartIndex = yearCurrentStartIndex;
                        yearLastEndIndex = yearLastStartIndex;
                    } else {
                        monthLastStartIndex = today.getMonth() - 1;
                        monthCurrentStartIndex = today.getMonth() - 1;
                        yearCurrentStartIndex = yearCurrentEndIndex;
                        yearLastStartIndex = yearCurrentEndIndex;
                        yearLastEndIndex = yearLastStartIndex;
                    }
                    monthLastEndIndex = monthLastStartIndex;
                } else if (today.getDate() <= 16) {
                    currentStartInfo += '1';
                    lastStartInfo = monthLast + ' ' + '16';
                    lastEndInfo = monthCurr + ' ' + '1';

                    monthLastStartIndex = today.getMonth() - 1;
                    monthLastEndIndex = today.getMonth();
                    monthCurrentEndIndex = today.getMonth();
                    monthCurrentStartIndex = monthCurrentEndIndex;
                    dateCurrentEndIndex = today.getDate();
                    dateCurrentStartIndex = 1;
                    dateLastEndIndex = 1;
                    dateLastStartIndex = 16;
                    yearCurrentEndIndex = today.getFullYear();
                    yearCurrentStartIndex = yearCurrentEndIndex;
                    yearLastEndIndex = yearCurrentStartIndex;
                    yearLastStartIndex = yearLastEndIndex;
                    if (today.getMonth() - 1 < 0) {
                        monthLastStartIndex = month.length - 1;
                        yearLastEndIndex = yearCurrentStartIndex;
                        yearLastStartIndex = yearLastEndIndex - 1;
                    }
                } else {
                    currentStartInfo += '16';
                    lastStartInfo = monthCurr + ' ' + '1';
                    lastEndInfo = monthCurr + ' ' + '16';

                    monthLastStartIndex = today.getMonth();
                    monthLastEndIndex = today.getMonth();
                    monthCurrentStartIndex = monthLastStartIndex;
                    monthCurrentEndIndex = monthCurrentStartIndex;
                    dateCurrentEndIndex = today.getDate();
                    dateCurrentStartIndex = 16;
                    dateLastStartIndex = 1;
                    dateLastEndIndex = 16;
                    yearCurrentEndIndex = today.getFullYear();
                    yearCurrentStartIndex = yearCurrentEndIndex;
                    yearLastStartIndex = yearCurrentStartIndex;
                    yearLastEndIndex = yearLastStartIndex;
                }
                setCurrentStart(currentStartInfo);
                setCurrentEnd(currentEndInfo);
                setLastStart(lastStartInfo);
                setLastEnd(lastEndInfo);
                dateCurrentStartIndex = dateCurrentStartIndex < 10 ? '0' + dateCurrentStartIndex : dateCurrentStartIndex;
                dateCurrentEndIndex = dateCurrentEndIndex < 10 ? '0' + dateCurrentEndIndex : dateCurrentEndIndex;
                dateLastStartIndex = dateLastStartIndex < 10 ? '0' + dateLastStartIndex : dateLastStartIndex;
                dateLastEndIndex = dateLastEndIndex < 10 ? '0' + dateLastEndIndex : dateLastEndIndex;
                currentStartTime = yearCurrentStartIndex + '' + monthNum[monthCurrentStartIndex] + dateCurrentStartIndex;
                currentEndTime = yearCurrentEndIndex + '' + monthNum[monthCurrentEndIndex] + dateCurrentEndIndex;
                lastStartTime = yearLastStartIndex + '' + monthNum[monthLastStartIndex] + dateLastStartIndex;
                lastEndTime = yearCurrentEndIndex + '' + monthNum[monthLastEndIndex] + dateLastEndIndex;
                props.handleLastPeriod(lastStartInfo, lastEndInfo, lastStartTime, lastEndTime);
                window.localStorage.setItem('lastStart', lastStartInfo);
                window.localStorage.setItem('lastEnd', lastEndInfo);
                window.localStorage.setItem('lastStartTime', lastStartTime);
                window.localStorage.setItem('lastEndTime', lastEndTime);
                setCurrentStartTime(currentStartTime);
                setCurrentEndTime(currentEndTime);
                if (props.open) {
                    setLoading(true);
                    if (period === 0) {
                        if (currentStartTime && currentEndTime) {
                            dispatch(getOrderAndSalary(currentStartTime, currentEndTime))
                                .then((res) => {
                                    window.localStorage.setItem('email', res.driverinfo[0].email);
                                    setLoading(false);
                                    setAmount(res.data[0].amt)
                                    setCompleted(res.data[0].completed);
                                    setIncompleted(res.data[0].incompleted);
                                    setOverweightOrders(res.data[0].owerweight);
                                    setOverweightAmount(res.data[0].owamt);
                                    setAllowance(res.data[0].amt_adj);
                                })
                                .catch((err) => {
                                    setLoading(false);
                                })
                        }
                    } else if (period === 1) {
                        if (lastStartTime, lastEndTime) {
                            dispatch(getOrderAndSalary(lastStartTime, lastEndTime))
                                .then((res) => {
                                    window.localStorage.setItem('email', res.driverinfo[0].email);
                                    setLoading(false);
                                    setSalaryInfo(res.data);
                                })
                                .catch((err) => {
                                    setLoading(false);
                                })
                        }
                    }
                }
            }
        }
    }, [props.open, period, menu])
    useEffect(() => {
        setMenu(Number(window.localStorage.getItem('menu')));
    }, [window.localStorage])
    useEffect(() => {
        if(menu === 1) {
            setT4aGenerated(false);
            dispatch(searchDriver(window.localStorage.getItem('driver_id')))
            .then((res) => {
                if(!res) {
                    dispatch(showAlertSnackbar({message: 'T4A is not generated!'}));
                    setT4aGenerated(false);
                    setMenu(0);
                } else {
                    setT4aGenerated(true);
                }
            })
            .catch(() => {
                dispatch(showAlertSnackbar({message: 'T4A is not generated!'}));
                setT4aGenerated(false);
                setMenu(0);
            })
        }
    }, [menu])

    return (
        <div>
            {
                menu === 0 &&
                <div className={classes.root}>
                    <div>
                        <MenuIcon 
                            fontSize='large'
                            onClick={()=>{
                                toggleDrawer(true)
                            }}
                        />
                    </div>
                        <div className={classes.overviewTitle}>
                            {
                                process.env.REACT_APP_COUNTRY === 'US' ? t('title_us') : t('title')
                            }
                        </div>
                        <div className={classes.overViewUsername}>
                            {
                                process.env.REACT_APP_COUNTRY === 'US' ? subjectId + '.' + username : username
                            }
                        </div>
                    <div className={classes.selectPeriod}>
                        <Select
                            fullWidth
                            value={period}
                            onChange={handlePeriodChange}
                            input={<BootstrapInput />}
                            MenuProps={{
                                anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                                },
                                transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                                },
                                getContentAnchorEl: null
                            }}
                        >
                            <MenuItem 
                                value={0} 
                                classes={{selected: classes.selectedMenuItem}}
                                style={{
                                    height: 44,
                                    width: 295,
                                    borderRadius: 7,
                                    margin: 10 
                                }}
                            >
                                    {
                                        process.env.REACT_APP_COUNTRY === 'US' ?
                                            t('current_payment_us') : t('current_payment')
                                    }
                            </MenuItem>
                                {
                                    process.env.REACT_APP_COUNTRY === 'CA' &&
                                    <MenuItem
                                        value={1}
                                            classes={{ selected: classes.selectedMenuItem }}
                                            style={{
                                                height: 44,
                                                width: 295,
                                                borderRadius: 7,
                                                margin: 10
                                            }}
                                        >
                                            {t('last_payment')}
                                        </MenuItem>
                                }
                            <MenuItem 
                                value={2}
                                classes={{selected: classes.selectedMenuItemHistory}}
                                style={{
                                    height: 44,
                                    width: 295,
                                    margin: 10,
                                    paddingTop: 10,
                                    borderTop: '1px solid rgba(16,24,32,0.2)'
                                }}
                            >
                                <div
                                    style={{display: 'flex', alignItems: 'center'}}
                                >
                                        {
                                            process.env.REACT_APP_COUNTRY === 'CA' &&
                                            <MonetizationOnIcon style={{ marginRight: 12 }} />
                                        }
                                        <div style={{ marginRight: 5 }}>
                                            {
                                                process.env.REACT_APP_COUNTRY === 'US' ? t('history_income_us') : t('history_income')
                                            }
                                        </div>
                                    <ArrowRightAltIcon/>
                                </div>
                            </MenuItem>
                        </Select>
                    </div>
                    <div className={classes.overviewContent}>
                        {
                            period === 0 &&
                            <div className={classes.overViewDate}>
                                        {
                                            process.env.REACT_APP_COUNTRY === 'US' ? Object.keys(currentDateRange)[0] : { currentStart } - { currentEnd }
                                        }
                            </div>
                        }
                        {
                            period === 1 &&
                            <div className={classes.dateDownload}>
                                <div className={classes.overViewDate}>
                                    {lastStart} - {lastEnd}
                                </div>
                                <div>
                                    <Button
                                        style={{
                                            backgroundColor: 'white',
                                            height: 25,
                                            border: '2px solid rgba(16,24,32,0.3)',
                                            borderRadius: 12.5,
                                            color: '#101820',
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                            letterSpacing: 0,
                                            textAlign: 'center',
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            paddingLeft: 9,
                                            paddingRight: 9
                                        }}
                                    >
                                        <span 
                                            style={{textAlign: 'center'}}
                                            onClick={() => {
                                                clickPopupOpen(2)
                                            }}
                                        >
                                            {t('download')}
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        }
                        {
                            period === 0 &&
                            <div>
                                        {
                                            process.env.REACT_APP_COUNTRY === 'CA' &&
                                            <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginTop: 33 }}>
                                                <span className={classes.incomeTitle}>{t('estimated_income')}</span>
                                                {
                                                    amount ?
                                                        <span className={classes.incomeAmount}>
                                                            $ {amount.toFixed(2)}
                                                        </span>
                                                        :
                                                        <span className={classes.incomeAmount}>$0</span>
                                                }
                                            </div>
                                        }
                                        {
                                            process.env.REACT_APP_COUNTRY === 'US' &&
                                            <div className={classes.overviewTable} style={{ border: 'none' }}>
                                                <div className={classes.overviewTableRow} style={{ backgroundColor: "#FFE5CD4D" }}>
                                                    <div className={classes.overviewTableDetail} style={{ borderBottom: '3px solid #0D1923' }}>
                                                        <div>{t('completed_orders')}</div>
                                                        <div>{currentPayment?.complete}</div>
                                                    </div>
                                                </div>
                                                <div className={classes.overviewTableRow} style={{ backgroundColor: "#FFE5CD4D" }}>
                                                    <div className={classes.overviewTableDetail} style={{ borderBottom: '1px solid #8A8B8A' }}>
                                                        <div>{t('smaller')}</div>
                                                        <div>{currentPayment?.lt}</div>
                                                    </div>
                                                </div>
                                                <div className={classes.overviewTableRow} style={{ backgroundColor: "#FFE5CD4D" }}>
                                                    <div className={classes.overviewTableDetail} style={{ borderBottom: '1px solid #8A8B8A' }}>
                                                        <div>{t('between')}</div>
                                                        <div>{currentPayment?.between}</div>
                                                    </div>
                                                </div>
                                                <div className={classes.overviewTableRow} style={{ backgroundColor: "#FFE5CD4D" }}>
                                                    <div className={classes.overviewTableDetail}>
                                                        <div>{t('larger')}</div>
                                                        <div>{currentPayment?.gt}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            process.env.REACT_APP_COUNTRY === 'US' &&
                                            <div className={classes.overviewTableRow} style={{ marginTop: 20 }}>
                                                <div className={classes.overviewTableDetail}>
                                                    <div>{t('overdue')}</div>
                                                    <div>{currentPayment?.overdue}</div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            process.env.REACT_APP_COUNTRY === 'CA' &&
                                            <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginTop: 33 }}>
                                                <span className={classes.incomeTitle}>{t('estimated_income')}</span>
                                                {
                                                    amount ?
                                                        <span className={classes.incomeAmount}>
                                                            $ {amount.toFixed(2)}
                                                        </span>
                                                        :
                                                        <span className={classes.incomeAmount}>$0</span>
                                                }
                                            </div>
                                        }
                                        {
                                            process.env.REACT_APP_COUNTRY === 'CA' &&
                                <div className={classes.overviewTable}>
                                    <div className={classes.overviewTableRow}>
                                        <div className={classes.overviewTableDetail} style={{borderBottom: '1px solid #8A8B8A'}}>
                                            <div>{t('completed_orders')}</div>
                                            <div>{completed}</div>
                                        </div>
                                                    </div>
                                    <div className={classes.overviewTableRow}>
                                        <div className={classes.overviewTableDetail} style={{borderBottom: '1px solid #8A8B8A'}}>
                                            <div>{t('overweight_orders')}</div>
                                            <div>{overweightOrders}</div>
                                        </div>
                                    </div>
                                                    <div className={classes.overviewTableRow}>
                                        <div className={classes.overviewTableDetail} style={{borderBottom: '1px solid #8A8B8A'}}>
                                            <div>{t('overweight_amount')}</div>
                                            <div>{overweightAmount}</div>
                                        </div>
                                    </div>
                                    <div className={classes.overviewTableRow}>
                                        <div className={classes.overviewTableDetail}>
                                            <div>{t('allowance_deduction')}</div>
                                            <div>{allowance}</div>
                                        </div>
                                    </div>
                                </div>
                                        }
                                        {
                                            process.env.REACT_APP_COUNTRY === 'CA' &&
                                            <div className={classes.overviewTableDescription}>
                                                {t('notice')}
                                            </div>
                                        }
                                        {
                                            process.env.REACT_APP_COUNTRY === 'CA' ? 
                                                <div className={classes.viewDetails} onClick={viewDetails}>
                                                    < div className={classes.viewDetailsText}>{t('delivery_details')}</div>
                                                    <ArrowRightAltIcon style={{ color: "#F68D2E" }} />
                                                </div>
                                                :
                                                <div className={classes.viewDetails} onClick={viewDetails} style={{ marginLeft: 140 }}>
                                                    < div className={classes.viewDetailsText} style={{ width: 200 }}>{t('delivery_details_us')}</div>
                                                    <ArrowRightAltIcon style={{ color: "#F68D2E" }} />
                                                </div>
                                        }
                            </div>
                        }
                        {
                            period === 1 &&
                            <IncomeCompleteTable type='income-overview' salaryInfo={salaryInfo}/>
                        }
                    </div>
                </div>
            }
            {
                menu === 1 &&
                t4aGenerated &&
                <div>
                    <div style={{marginLeft:20, marginTop: 22}}>
                        <MenuIcon 
                            fontSize='large'
                            onClick={()=>{
                                toggleDrawer(true)
                            }}
                        />
                    </div>
                    <T4A/>
                </div>
                
            }
            <Drawer anchor='left' open={menuState} onClose={()=>toggleDrawer(false)}>
                <div
                    className={classes.drawerStyle}
                    onClick={()=>toggleDrawer(false)}
                    onKeyDown={()=>toggleDrawer(false)}
                >
                    <div>
                        <MenuIcon 
                            fontSize='large'
                            style={{color: "#F68D2E"}}
                        />
                    </div>
                    <div 
                        className={classes.menuTitle}
                        onClick={() => {
                            setMenu(0);
                            window.localStorage.setItem('menu', 0);
                        }}
                        style={
                            menu === 0 ?
                            {color: '#EA882D'} :
                            {color: 'white'}
                        }
                    >
                        {t('title')}
                    </div>
                    {/* Hide T4A */}
                    {/* <div 
                        className={classes.menuTitle}
                        onClick={() => {
                            setMenu(1);
                            window.localStorage.setItem('menu', 1);
                        }}
                        style={
                            menu === 1 ?
                            {color: '#EA882D'} :
                            {color: 'white'}
                        }
                    >
                        T4A
                    </div> */}
                    <div className={classes.languageTitle}>
                        {t('language')}
                    </div>
                    {/* <div 
                        className={classes.language}
                        style={
                            i18n.language==='cn' ?
                            {
                                borderBottom: '1px solid #FFFFFF',
                                width: 'fit-content'
                            }:{
                                border: 'none'
                            }
                        }
                        onClick={() => changeLanguagePref(i18n, "cn")}
                    >
                        中文
                    </div> */}
                    <div 
                        className={classes.language}
                        style={
                            i18n.language==='en' ?
                            {
                                borderBottom: '1px solid #FFFFFF',
                                width: 'fit-content'
                            }:{
                                border: 'none'
                            }
                        }
                        onClick={() => changeLanguagePref(i18n, "en")}
                    >
                        English
                    </div>
                    {/* <div 
                        className={classes.language}
                        style={
                            i18n.language==='fr' ?
                            {
                                borderBottom: '1px solid #FFFFFF',
                                width: 'fit-content'
                            }:{
                                border: 'none'
                            }
                        }
                        onClick={() => changeLanguagePref(i18n, "fr")}
                    >
                        Français
                    </div> */}
                </div>
                <Divider/>
                <div
                    style={{
                        height: '10vh',
                        opacity: 0.9,
                        backgroundColor: '#101820',
                        width: 215,
                        padding: 30
                    }}
                >   
                    <div style={{ 
                        color: '#FFFFFF',
                        fontSize: 16,
                        fontWeight: 'bold',
                        letterSpacing: 0
                        }}
                        onClick={()=>userLogout(true)}
                    >
                        {t('log_out')}
                    </div>
                </div>
            </Drawer>
            <FullscreenLoading open={loading} />
            <PopUp style={style} open={popupOpen} handlePopupClose={handlePopupClose} type='income' timeStart={window.localStorage.getItem('lastStartTime')} timeEnd={window.localStorage.getItem('lastEndTime')}/>
        </div>
    )
}
export default IncomeOverview;