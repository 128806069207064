import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function DriverlistTable(props) {
    const {t} = useTranslation('common');

    const [driverlist, setDriverlist] = useState([]);

    const columns = [
        { 
            field: 'id', 
            headerName: t('driver_id'), 
            width: 150
        },
        { 
            field: '姓名', 
            headerName: t('name'), 
            width: 400
        },
        { 
            field: '单价', 
            headerName: t('salary'), 
            width: 200
        },
        { 
            field: '是否服务号', 
            headerName: t('service_no'), 
            width: 200
        },
        { 
            field: '是否小时司机', 
            headerName: t('hour_driver'), 
            width: 150
        },
        { 
            field: '所属小组', 
            headerName: t('team'), 
            width: 200
        },
        { 
            field: '活跃度', 
            headerName: t('activity'), 
            width: 200
        }
    ]

    function createRows(){
        return driverlist.map((row) => {
            const {司机ID, ...rest} = row
            return {id: 司机ID, ...rest}
        })
    }

    useEffect(() => {
        if(props.driverlist) {
            if(props.driverlist.length > 0) {
                setDriverlist(props.driverlist);
            }
        }
    }, [props.driverlist])

    return (
        <div style={{ height: 600, width: '90%', marginTop: 200, marginLeft: 'auto', marginRight: 'auto' }}>
            <DataGrid
                rows={createRows()}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
            />
        </div>
    )
}
export default DriverlistTable;