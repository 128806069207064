import { type } from '../actions/typesMobileUS';

const initialState = {
    getCurrentPayment: {},
    getCurrentPaymentLoading: false,
    getCurrentPaymentError: false,

    getCurrentPaymentPeriod: {},
    getCurrentPaymentPeriodLoading: false,
    getCurrentPaymentPeriodError: false,

    getPaymentPeriod: {},
    getPaymentPeriodLoading: false,
    getPaymentPeriodError: false,

    getSettlementTimeList: [],
    getSettlementTimeListLoading: false,
    getSettlementTimeListError: false,

    details: {}
}

export default function driverUSReducer(state = initialState, action) {
    switch (action.type) {
        case type.GET_PAYMENT_PERIOD:
            return {
                ...state,
                getPaymentPeriodLoading: true,
            }
        case type.GET_PAYMENT_PERIOD_SUCCESS:
            return {
                ...state,
                getPaymentPeriod: action.payload,
                ggetPaymentPeriodLoading: false
            }
        case type.GET_PAYMENT_PERIOD_FAIL:
            return {
                ...state,
                getPaymentPeriodLoading: false,
                getPaymentPeriodError: action.payload
            }
        case type.GET_CURRENT_PAYMENT_PERIOD:
            return {
                ...state,
                getCurrentPaymentPeriodLoading: true,
            }
        case type.GET_CURRENT_PAYMENT_PERIOD_SUCCESS:
            return {
                ...state,
                getCurrentPaymentPeriod: action.payload,
                ggetCurrentPaymentPeriodLoading: false
            }
        case type.GET_CURRENT_PAYMENT_PERIOD_FAIL:
            return {
                ...state,
                getCurrentPaymentPeriodLoading: false,
                getCurrentPaymentPeriodError: action.payload
            }
        case type.HANDLE_DETAILS:
            return {
                ...state,
                details: action.payload,
            }
        case type.GET_CURRENT_PAYMENT:
            return {
                ...state,
                getCurrentPaymentLoading: true,
            }
        case type.GET_CURRENT_PAYMENT_SUCCESS:
            return {
                ...state,
                getCurrentPayment: action.payload,
                getCurrentPaymentLoading: false
            }
        case type.GET_CURRENT_PAYMENT_FAIL:
            return {
                ...state,
                getCurrentPaymentLoading: false,
                getCurrentPaymentError: action.payload
            }
        case type.GET_SETTLEMENT_TIME_LIST:
            return {
                ...state,
                getSettlementTimeListLoading: true,
            }
        case type.GET_SETTLEMENT_TIME_LIST_SUCCESS:
            return {
                ...state,
                getSettlementTimeList: action.payload,
                getSettlementTimeListLoading: false
            }
        case type.GET_SETTLEMENT_TIME_LIST_FAIL:
            return {
                ...state,
                getSettlementTimeListLoading: false,
                getSettlementTimeListError: action.payload
            }
        default:
            return state;
    }
}