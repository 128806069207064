export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';

export const GET_ORDER_COUNT = 'GET_ORDER_COUNT';
export const GET_ORDER_COUNT_SUCCESS = 'GET_ORDER_COUNT_SUCCESS';
export const GET_ORDER_COUNT_FAIL = 'GET_ORDER_COUNT_FAIL';

export const GET_SALARY = 'GET_SALARY';
export const GET_SALARY_SUCCESS = 'GET_SALARY_SUCCESS';
export const GET_SALARY_FAIL = 'GET_SALARY_FAIL';

export const GET_DRIVERLIST = 'GET_DRIVERLIST';
export const GET_DRIVERLIST_SUCCESS = 'GET_DRIVERLIST_SUCCESS';
export const GET_DRIVERLIST_FAIL = 'GET_DRIVERLIST_FAIL';

export const GET_LAGET_SALARY = 'GET_LAGET_SALARY';
export const GET_LAGET_SALARY_SUCCESS = 'GET_LAGET_SALARY_SUCCESS';
export const GET_LAGET_SALARY_FAIL = 'GET_LAGET_SALARY_FAIL'

export const GET_LAGET_SALARY_LA = 'GET_LAGET_SALARY_LA';
export const GET_LAGET_SALARY_LA_SUCCESS = 'GET_LAGET_SALARY_LA_SUCCESS';
export const GET_LAGET_SALARY_LA_FAIL = 'GET_LAGET_SALARY_LA_FAIL';

// T4A
export const SEARCH_DRIVER = "SEARCH_DRIVER"
export const SEARCH_DRIVER_SUCCESS = "SEARCH_DRIVER_SUCCESS"
export const SEARCH_DRIVER_FAIL = "SEARCH_DRIVER_FAIL"

export const EDIT_DRIVER = "EDIT_DRIVER"
export const EDIT_DRIVER_SUCCESS = "EDIT_DRIVER_SUCCESS"
export const EDIT_DRIVER_FAIL = "EDIT_DRIVER_FAIL"

export const DOWNLOAD_PDF = "DOWNLOAD_PDF"
export const DOWNLOAD_PDF_SUCCESS = "DOWNLOAD_PDF_SUCCESS"
export const DOWNLOAD_PDF_FAIL = "DOWNLOAD_PDF_FAIL"

export const DRIVER_LOGIN = "DRIVER_LOGIN"
export const DRIVER_LOGIN_SUCCESS = "DRIVER_LOGIN_SUCCESS"
export const DRIVER_LOGIN_FAIL = "DRIVER_LOGIN_FAIL"

export const SEND_EMAIL = "SEND_EMAIL"
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS"
export const SEND_EMAIL_FAIL = "SEND_EMAIL_FAIL"