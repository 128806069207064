import Axios from "axios";
import { userLogout } from "../actions/userActions";
import { ErrorTypes } from "../constants/general";
import NetworkError from "../models/error";
import { getUserPreference } from "../utilities/common";

// test server
export const settlementServerInstance = Axios.create({
    baseURL: process.env.REACT_APP_SETTLEMENT_US,
    timeout: 60000,
    headers: {
        common: {
            'Authorization': `Bearer ${getUserPreference().authToken}`,
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    }
});

settlementServerInstance.interceptors.response.use(response => {
    //2xx
    if (response.status === 200 && response?.data?.retCode === 'SUCCESS') {
        return response
    } else {
        let error = new NetworkError(ErrorTypes.JSON, response.data.retCode, response.data.retMsg)
        if (error) {
            if (error.code === -2 || error.code === -4) {
                userLogout();
            }
        }
        throw error
    }
}, err => {
    //outside 2xx
    let error
    if (!err.response) {
        throw new NetworkError(ErrorTypes.JSON, "Network Error", "Please contact administrators to resolve this issue!")
    }
    if (err.response.status === 401) {
        console.log("Login Token Expired! " + getUserPreference().authToken)
        userLogout()
    }
    if (typeof err.response.data === 'object') {
        let filePath = err.response.data.file
        let line = err.response.data.line
        error = new NetworkError(ErrorTypes.JSON, err.response.status, `${err.response.data.message} ${filePath} @ line: ${line}`)
    } else {
        error = new NetworkError(ErrorTypes.HTML, err.response.status, "See details for more info", err.response.data)
    }

    throw error
})


// shangjia server
export const shangjiaServerInstance = Axios.create({
    baseURL: process.env.REACT_APP_SJ_SERVER_URL,
    timeout: 60000,
    headers: {common: {
        'Authorization': `Bearer ${getUserPreference().authToken}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, origin, content-type, accept",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        "Accept": "application/json",
        "Content-Type": "application/json"
    }}
  });

shangjiaServerInstance.interceptors.response.use(response=>{
    //2xx
    if (response.data.status === "SUCCESS"){
        if ((response.data.data && response.data.data.original && response.data.data.original.error_code)) {
            if (response.data.data.original.error_code === "invalid_access_token") {
                console.log("Login Token Expired! " + getUserPreference().authToken);
                userLogout();
            }
        } else {
            return response
        }
    } else {        
        let error = new NetworkError(ErrorTypes.JSON, response.data.err_code, response.data.ret_msg)
        throw error
    }
}, err => {
    //outside 2xx
    let error
    if(!err.response){
        throw new NetworkError(ErrorTypes.JSON, "Network Error", "Please contact administrators to resolve this issue!")
    }
    if(err.response.status === 401){
        console.log("Login Token Expired! " + getUserPreference().authToken)
        userLogout()
    }
    if(typeof err.response.data === 'object'){
        let filePath = err.response.data.file
        let line = err.response.data.line
        error = new NetworkError(ErrorTypes.JSON, err.response.status, `${err.response.data.message} ${filePath} @ line: ${line}`)
    } else {
        error = new NetworkError(ErrorTypes.HTML, err.response.status, "See details for more info" , err.response.data)
    }
    throw error
})

// map server
export const acctServerInstance = Axios.create({
    baseURL: process.env.REACT_APP_ACCT_SERVER_URL,
    timeout: 60000,
    headers: {common: {
        'Authorization': `Bearer ${getUserPreference().authToken}`,
        "Accept": "application/json",
        "Content-Type": "application/json"
    }}
});

acctServerInstance.interceptors.response.use(response=>{
    //2xx
    if (response.data.status === "SUCCESS" || response.data.data.succ === 1){
        if ((response.data.data !== undefined && response.data.data.original !== undefined && response.data.data.original.error_code !== undefined)) {
            if (response.data.data.original.error_code === "invalid_access_token") {
                console.log("Login Token Expired! " + getUserPreference().authToken);
                userLogout();
            }
        } else {
            return response
        }
    } else {        
        let error = new NetworkError(ErrorTypes.JSON, response.data.err_code, response.data.ret_msg)
        if(response.data.data) {
            error = new NetworkError(ErrorTypes.JSON, response.data.err_code, response.data.ret_msg, response.data.data)
        }
        if(error) {
            if(error.code === -2 || error.code === -4) {
                userLogout();
            }
        }
        throw error
    }
}, err => {
    //outside 2xx
    let error
    if(!err.response){
        throw new NetworkError(ErrorTypes.JSON, "Network Error", "Please contact administrators to resolve this issue!")
    }
    if(err.response.status === 401){
        console.log("Login Token Expired! " + getUserPreference().authToken)
        userLogout()
    }
    if(typeof err.response.data === 'object'){
        let filePath = err.response.data.file
        let line = err.response.data.line
        error = new NetworkError(ErrorTypes.JSON, err.response.status, `${err.response.data.message} ${filePath} @ line: ${line}`)
    } else {
        error = new NetworkError(ErrorTypes.HTML, err.response.status, "See details for more info" , err.response.data)
    }
    
    throw error
})
