import { useEffect, useState } from "react";
import IncomeOverview from "../../../components/DriverMobile/IncomeOverview";

function MainDriverMobile() {

    const [incomeOverviewOpen, setIncomeOverviewOpen] = useState(false);
    const [incomeDetailOpen, setIncomeDetailOpen] = useState(false);
    const [lastStart, setLastStart] = useState('');
    const [lastEnd, setLastEnd] = useState('');
    const [lastStartTime, setLastStartTime] = useState(0);
    const [lastEndTime, setLastEndTime] = useState(0);

    function handleIncomeDetailOpen(open) {
        setIncomeDetailOpen(open);
        if (!open) {
            window.localStorage.setItem('income-overview-open', true)
        }
    }
    function handleLastPeriod(lastStart, lastEnd, lastStartTime, lastEndTime) {
        setLastStart(lastStart);
        setLastEnd(lastEnd);
        setLastStartTime(lastStartTime);
        setLastEndTime(lastEndTime);
    }

    useEffect(() => {
        setIncomeOverviewOpen(window.localStorage.getItem('income-overview-open') === 'true');
    }, [window.localStorage.getItem('income-overview-open')])

    return (
        <div>
            {
                incomeOverviewOpen &&
                <IncomeOverview
                    handleIncomeDetailOpen={handleIncomeDetailOpen}
                    open={incomeOverviewOpen}
                    handleLastPeriod={handleLastPeriod}
                />
            }
        </div>
    )
}
export default MainDriverMobile;