import { shangjiaServerInstance } from "../axios/instances";
import {
    SEARCH_DRIVER,
    SEARCH_DRIVER_SUCCESS,
    SEARCH_DRIVER_FAIL,
    EDIT_DRIVER,
    EDIT_DRIVER_SUCCESS,
    EDIT_DRIVER_FAIL,
    DOWNLOAD_PDF,
    DOWNLOAD_PDF_SUCCESS,
    DOWNLOAD_PDF_FAIL,
    DRIVER_LOGIN,
    DRIVER_LOGIN_SUCCESS,
    DRIVER_LOGIN_FAIL,
    SEND_EMAIL,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_FAIL
} from './types';

export function searchDriver(id){
    return function(dispatch){
        dispatch({type: SEARCH_DRIVER})
        return shangjiaServerInstance.get(`/account/drivert4as/${id}`)
        .then(({data}) => {
            dispatch({
                type: SEARCH_DRIVER_SUCCESS,
                payload: data.data
            })
            return Promise.resolve(data.data);
        }).catch(error => {
            dispatch({
                type: SEARCH_DRIVER_FAIL,
                payload: error
            })
            return Promise.reject(error);
        })
    }
}

export function editDriver(id, params){
    return function(dispatch){
        dispatch({type: EDIT_DRIVER})
        return shangjiaServerInstance.post(`/account/drivert4as/${id}`, {...params})
        .then(({data}) => {
            dispatch({
                type: EDIT_DRIVER_SUCCESS,
                payload: data.data
            })
            return Promise.resolve(data.data);
        }).catch(error => {
            dispatch({
                type: EDIT_DRIVER_FAIL,
                payload: error
            })
            return Promise.reject(error);
        })
    }
}

export function downloadPdf(id){
    return function(dispatch){
        dispatch({type: DOWNLOAD_PDF})
        return shangjiaServerInstance.get(`/account/drivert4apdf?driver_id=${id}`)
        .then(({data}) => {
            dispatch({
                type: DOWNLOAD_PDF_SUCCESS,
                payload: data.data
            })
            return Promise.resolve(data.data);
        }).catch(error => {
            dispatch({
                type: DOWNLOAD_PDF_FAIL,
                payload: error
            })
            return Promise.reject(error);
        })
    }
}

export function driverLogin(username, password){
    return function(dispatch){
        dispatch({type: DRIVER_LOGIN})
        return shangjiaServerInstance.post(`/account/driverlogin?username=${username}&password=${password}`)
            .then(({data}) => {
                dispatch({
                    type: DRIVER_LOGIN_SUCCESS,
                    payload: data.data
                })
                window.localStorage.setItem('token', data.data.token);
                shangjiaServerInstance.defaults.headers.common['Authorization'] = `Bearer ${data.data.token}`
                return Promise.resolve(data.data);
            }).catch(error => {
                dispatch({
                    type: DRIVER_LOGIN_FAIL,
                    payload: error
                })
                return Promise.reject(error);
            });
    }
}

export function sendEmail(id, email){
    return function(dispatch){
        dispatch({type: SEND_EMAIL})
        return shangjiaServerInstance.post(`/account/emaildrivert4apdf`, {
            driver_id: id,
            email: email
        })
        .then(({data}) => {
            dispatch({
                type: SEND_EMAIL_SUCCESS,
                payload: data.data
            })
            return Promise.resolve(data.data);
        }).catch(error => {
            dispatch({
                type: SEND_EMAIL_FAIL,
                payload: error
            })
            return Promise.reject(error);
        })
    }
}