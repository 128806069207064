import { useEffect, useState } from "react";
import DownloadT4A from "../../../components/T4A/DownloadT4A";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useHistory } from "react-router-dom";

function DownloadT4APage() {
    const history = useHistory();

    return (
        <div>
            <div
                style={{
                    marginLeft: 20,
                    marginTop: 22
                }}
                onClick={() => {
                    history.goBack();  
                }}
            >
                <KeyboardBackspaceIcon fontSize='large' />
            </div>
            <img 
                src='/images/group-copy.svg' 
                style={{
                    height: 42,
                    width: 178,
                    marginTop: 22,
                    marginLeft: 20
                }}
            />
            <DownloadT4A />
        </div>
    )
}
export default DownloadT4APage;