import { 
    SEARCH_DRIVER,
    SEARCH_DRIVER_SUCCESS,
    SEARCH_DRIVER_FAIL
 } from '../actions/types';

const initialState = {
    searchDriverLoading: false,
    searchDriver: {},
    searchDriverError: false
}

export default function t4aReducer(state = initialState, action){
    switch(action.type){
        case SEARCH_DRIVER:
            return {
                ...state,
                searchDriverLoading: true,
            }
        case SEARCH_DRIVER_SUCCESS:
            return {
                ...state,
                searchDriver: action.payload,
                searchDriverLoading: false
            }
        case SEARCH_DRIVER_FAIL:
            return {
                ...state,
                searchDriverLoading: false,
                searchDriverError: action.payload
            }
        default:
            return state;
    }
}