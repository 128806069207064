import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
    darkTheme: false,
    openAlertSnackbar: false,
    alertObject: {
        type: 'error',
        title: undefined,
        message: 0,
        position: {
            vertical: "top",
            horizontal: "center"
        },
        duration: 5000
    }
}

export const slice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        showAlertSnackbar: (state, action) => {
            state.alertObject = {
                ...state.alertObject,
                type: (action.payload.type ? action.payload.type : "error"),
                duration: action.payload.duration || 6000,
                title: action.payload.title,
                message: action.payload.message
            }
            state.openAlertSnackbar = true
        },
        hideAlertSnackbar: (state) => {
            state.openAlertSnackbar = false
            state.alertObject = initialState.alertObject
        },
    }
})

export const {
    hideAlertSnackbar,
    showAlertSnackbar
} = slice.actions;

export const currentTheme = state => state.ui.darkTheme;
export const openAlertSnackbar = state => state.ui.openAlertSnackbar

export default slice.reducer;