import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@mui/icons-material/Help';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import { CSVLink } from "react-csv";
import { Dialog, DialogTitle, Snackbar, DialogContent, Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
        borderBottom: 'unset',
        },
    },
    download: { 
        textDecoration: 'none', color: '#212121'
    },
    margin: {
        marginLeft: 5
    }
});

export default function StatisticsTable(props) {
    const { driverData, identity, driverInfo } = props;
    const classes = useRowStyles();
    const {t} = useTranslation('common');

    const moment = require('moment');

    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [tnosDisplay, setTnosDisplay] = useState([]);
    const [tnos, setTnos] = useState([]);
    const [rowOpen, setRowOpen] = useState(false);
    const [descroptionOpen, setDescriptionOpen] = useState(false);
    const [description, setDescription] = useState('');
    const [driver, setDriver] = useState('');
    const [isSalaried, setIsSalaried] = useState(false);

    function displayOrders(tnos, isC5 = false) {
        setIsSalaried(isC5);
        if(tnos.length > 0) {
            setTnos(tnos);
            handleClickOpen();
        }
    }
    function clickDescriptionOpen(num) {
        if(num === 0) {
            setDescription(t('orders_des'));
            setDescriptionOpen(true);
        } else if(num === 1) {
            setDescription(t('completed_des'));
            setDescriptionOpen(true);
        } else if(num === 2) {
            setDescription(t('transferred_des'));
            setDescriptionOpen(true);
        } else if(num === 3) {
            setDescription(t('pending_des'));
            setDescriptionOpen(true);
        } else if(num === 4) {
            setDescription(t('settled_des'));
            setDescriptionOpen(true);
        } else if(num === 5) {
            setDescription(t('to_be_settled_des'));
            setDescriptionOpen(true);
        }
    }

    const handleClickOpen = () => {
        setRowOpen(true);
    };
    const handleClose = () => {
        setRowOpen(false);
    };
    const handleDescriptionClose = () => {
        setDescriptionOpen(false);
    }

    const columns = [
        { 
            field: 'time_display', 
            headerName: t('update_time'), 
            width: 110
        },
        { 
            field: 'pack_id', 
            headerName: t('pack_id'), 
            width: 110
        },
        {
            field: 'tno',
            headerName: t('tno'),
            width: 200
        },
        {
            field: 'pathInfo',
            headerName: t('pathInfo'),
            width: 300
        },
        {
            field: 'partner',
            headerName: t('partner'),
            width: 200
        },
        {
            field: 'zipcode',
            headerName: t('zip_code'),
            width: 150
        },
        {
            field: 'issecond', 
            headerName: t('is_second'), 
            width: 200, 
            headerAlign: 'center',
            valueFormatter: (params)=>params.value===1?t('Yes'):'',
            align: 'center'
        }
    ];   

    const columns5 = [
        { 
            field: 'time_display', 
            headerName: t('update_time'), 
            width: 110
        },
        { 
            field: 'pack_id', 
            headerName: t('pack_id'), 
            width: 110
        },
        {
            field: 'tno',
            headerName: t('tno'),
            width: 200
        },
        {
            field: 'pathInfo',
            headerName: t('pathInfo'),
            width: 300
        },
        {
            field: 'partner',
            headerName: t('partner'),
            width: 200
        },
        {
            field: 'zipcode',
            headerName: t('zip_code'),
            width: 150
        },
        {
            field: 'issecond', 
            headerName: t('is_second'), 
            width: 200, 
            headerAlign: 'center',
            valueFormatter: (params)=>params.value===1?t('Yes'):'',
            align: 'center'
        },
        {
            field: 'amount',
            headerName: t('price'),
            width: 150
        }
    ];      

    useEffect(() => {
        if(driverData) {
            setRows(driverData);
        }
    }, [driverData])
    useEffect(() => {
        if(driverInfo) {
            if(driverInfo[0]) {
                setDriver(driverInfo[0].name);
            }
        }
    }, [driverInfo])
    useEffect(() => { 
        if(tnos) {
            let tnosCopy = tnos;
            tnosCopy.map((tno) => {
                if(tno.time) {
                    tno.time_display = moment(tno.time*1000).format('MM-DD HH:mm');
                } else {
                    tno.time_display = moment(tno.update_time*1000).format('MM-DD HH:mm');
                }
            })
            setTnosDisplay(tnosCopy);
        }
    }, [tnos])

  return (
        <React.Fragment>
            <Dialog fullWidth maxWidth='lg' onClose={handleClose} aria-labelledby="customized-dialog-title" open={rowOpen}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {t('tnos')}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <TableContainer className={classes.container}>
                            <div style={{ height: 500, width: '100%' }}>
                                <DataGrid
                                    rows={tnosDisplay}
                                    columns={isSalaried?columns5:columns}
                                    pageSize={8}
                                    rowsPerPageOptions={[5]}
                                    disableSelectionOnClick
                                />
                            </div>
                        </TableContainer>
                    </Typography>
                </DialogContent>
            </Dialog>
            <Snackbar open={descroptionOpen} autoHideDuration={6000} onClose={handleDescriptionClose}>
                <Alert variant="filled" severity="info" onClose={handleDescriptionClose}>
                    {description}
                </Alert>
            </Snackbar>
            <div hidden={!identity}>
                {
                    driver &&
                    <div
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop: 20,
                            width: 'fit-content',
                            padding: 10,
                            backgroundColor: '#9CCC65',
                            border: '1px solid #558B2F',
                            borderRadius: 5
                        }}
                    >{t('driver')}{driver}</div>
                }
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>
                                {t('date')}
                            </TableCell>
                            {/* <TableCell 
                                align="center" 
                                onClick={()=>clickDescriptionOpen(0)}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('orders')}</div>
                                    <HelpIcon
                                        style={{ width: 16 }}
                                    />
                                </div>
                            </TableCell> */}
                            {/* <TableCell 
                                align="center" 
                                onClick={()=>clickDescriptionOpen(4)}
                            >                                
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('settled')}</div>
                                    <HelpIcon
                                        style={{ width: 16 }}
                                    />
                                </div>
                            </TableCell> */}
                            {/* <TableCell 
                                align="center" 
                                onClick={()=>clickDescriptionOpen(5)}
                            >                                
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('to_be_settled')}</div>
                                    <HelpIcon
                                        style={{ width: 16 }}
                                    />
                                </div>
                            </TableCell> */}
                            {/* <TableCell 
                                align="center" 
                                onClick={()=>clickDescriptionOpen(3)}
                            >                                
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('pending')}</div>
                                    <HelpIcon
                                        style={{ width: 16 }}
                                    />
                                </div>
                            </TableCell> */}
                            <TableCell 
                                align="center" 
                                onClick={()=>clickDescriptionOpen(1)}
                            >                                
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('completed')}</div>
                                    <HelpIcon
                                        style={{ width: 16 }}
                                    />
                                </div>
                            </TableCell>
                            {/* <TableCell 
                                align="center" 
                                onClick={()=>clickDescriptionOpen(2)}
                            >                                
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('transferred')}</div>
                                    <HelpIcon
                                        style={{ width: 16 }}
                                    />
                                </div>
                            </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        rows?.length > 0 &&
                        // rows.map((row, index) => (
                            <TableRow>
                                <TableCell 
                                    align="center"
                                >
                                    {rows[0].date}
                                </TableCell>
                                {/* <TableCell 
                                    align="center" 
                                    onClick={()=>{displayOrders(row.c1)}}
                                >
                                    <div style={{
                                        display:'flex', flexDirection:'column', alignItems: 'center'
                                    }}>
                                        {row.c1.length ? row.c1.length : ''}
                                        <div style={{display: 'flex'}}>
                                            <Button
                                                onClick={()=>{displayOrders(row.c1)}}
                                                disabled={!(row.c1.length > 0)}
                                                color="secondary"
                                            >
                                                {t('view')}
                                            </Button>
                                            {
                                                index === 0
                                                &&
                                                <Button color="secondary" className={classes.margin} disabled={!(row.c1.length > 0)}>
                                                    <CSVLink 
                                                        data={ row.c1 || [] }
                                                        className={classes.download}
                                                    >
                                                        {t('download_excel')}
                                                    </CSVLink>
                                                </Button>
                                            }
                                        </div>    
                                    </div>
                                </TableCell> */}
                                {/* <TableCell 
                                    align="center" 
                                    onClick={()=>{displayOrders(row.c5, true)}}
                                >
                                    <div style={{
                                        display:'flex', flexDirection:'column', alignItems: 'center'
                                    }}>
                                        {row.c5.length ? row.c5.length : ''}
                                        <div style={{display: 'flex'}}>
                                            <Button
                                                onClick={()=>{displayOrders(row.c5, true)}}
                                                disabled={!(row.c5.length > 0)}
                                                color="secondary"
                                            >
                                                {t('view')}
                                            </Button>
                                            {
                                                index === 0
                                                &&
                                                <Button color="secondary" className={classes.margin} disabled={!(row.c5.length > 0)}>
                                                    <CSVLink 
                                                        data={ row.c5 || [] }
                                                        className={classes.download}
                                                    >
                                                        {t('download_excel')}
                                                    </CSVLink>
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </TableCell> */}
                                {/* <TableCell 
                                    align="center" 
                                    onClick={()=>{displayOrders(row.c6)}}
                                >
                                    <div style={{
                                        display:'flex', flexDirection:'column', alignItems: 'center'
                                    }}>
                                        {row.c6.length ? row.c6.length : ''}
                                        <div style={{display: 'flex'}}>
                                            <Button
                                                onClick={()=>{displayOrders(row.c6)}}
                                                disabled={!(row.c6.length > 0)}
                                                color="secondary"
                                            >
                                                {t('view')}
                                            </Button>
                                            {
                                                index === 0
                                                &&
                                                <Button color="secondary" className={classes.margin} disabled={!(row.c6.length > 0)}>
                                                    <CSVLink 
                                                        data={ row.c6 || [] }
                                                        className={classes.download}
                                                    >
                                                        {t('download_excel')}
                                                    </CSVLink>
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </TableCell> */}
                                {/* <TableCell 
                                    align="center" 
                                    onClick={()=>{displayOrders(row.c4)}}
                                >
                                    <div style={{
                                        display:'flex', flexDirection:'column', alignItems: 'center'
                                    }}>
                                        {row.c4.length ? row.c4.length : ''}
                                        <div style={{display: 'flex'}}>
                                            <Button
                                                onClick={()=>{displayOrders(row.c4)}}
                                                disabled={!(row.c4.length > 0)}
                                                color="secondary"
                                            >
                                                {t('view')}
                                            </Button>
                                            {
                                                index === 0
                                                &&
                                                <Button color="secondary" className={classes.margin} disabled={!(row.c4.length > 0)}>
                                                    <CSVLink 
                                                        data={ row.c4 || [] }
                                                        className={classes.download}
                                                    >
                                                        {t('download_excel')}
                                                    </CSVLink>
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </TableCell> */}
                                <TableCell 
                                    align="center" 
                                    onClick={()=>{displayOrders(rows[0].c2)}}
                                >
                                    <div style={{
                                        display:'flex', flexDirection:'column', alignItems: 'center'
                                    }}>
                                        {rows[0].c2.length ? rows[0].c2.length : ''}
                                        <div style={{display: 'flex'}}>
                                            <Button
                                                onClick={()=>{displayOrders(rows[0].c2)}}
                                                disabled={!(rows[0].c2.length > 0)}
                                                color="secondary"
                                            >
                                                {t('view')}
                                            </Button>
                                            {/* {
                                                index === 0
                                                && */}
                                                <Button color="secondary" className={classes.margin} disabled={!(rows[0].c2.length > 0)}>
                                                    <CSVLink 
                                                        data={ rows[0].c2 || [] }
                                                        className={classes.download}
                                                    >
                                                        {t('download_excel')}
                                                    </CSVLink>
                                                </Button>
                                            {/* } */}
                                        </div>
                                    </div>
                                </TableCell>
                                {/* <TableCell 
                                    align="center" 
                                    onClick={()=>{displayOrders(row.c3)}}
                                >
                                    <div style={{
                                        display:'flex', flexDirection:'column', alignItems: 'center'
                                    }}>
                                        {row.c3.length ? row.c3.length : ''}
                                        <div style={{display: 'flex'}}>
                                            <Button
                                                onClick={()=>{displayOrders(row.c3)}}
                                                disabled={!(row.c3.length > 0)}
                                                color="secondary"
                                            >
                                                {t('view')}
                                            </Button>
                                            {
                                                index === 0
                                                &&
                                                <Button color="secondary" className={classes.margin} disabled={!(row.c3.length > 0)}>
                                                    <CSVLink 
                                                        data={ row.c3 || [] }
                                                        className={classes.download}
                                                    >
                                                        {t('download_excel')}
                                                    </CSVLink>
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </TableCell> */}
                            </TableRow>
                        // ))
                    }
                    </TableBody>
                </Table>
            </div>
            <div hidden={identity} style={{marginTop: 60}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>
                                {t('date')}
                            </TableCell>
                            {/* <TableCell 
                                align="center" 
                                onClick={()=>clickDescriptionOpen(0)}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('orders')}</div>
                                    <HelpIcon
                                        style={{ width: 16 }}
                                    />
                                </div>
                            </TableCell> */}
                            {/* <TableCell 
                                align="center" 
                                onClick={()=>clickDescriptionOpen(3)}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('pending')}</div>
                                    <HelpIcon
                                        style={{ width: 16 }}
                                    />
                                </div>
                            </TableCell> */}
                            <TableCell 
                                align="center" 
                                onClick={()=>clickDescriptionOpen(1)}
                            >                                
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('completed')}</div>
                                    <HelpIcon
                                        style={{ width: 16 }}
                                    />
                                </div>
                            </TableCell>
                            {/* <TableCell 
                                align="center" 
                                onClick={()=>clickDescriptionOpen(2)}
                            >                                
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ paddingBottom: 2, marginRight: 2 }}>{t('transferred')}</div>
                                    <HelpIcon
                                        style={{ width: 16 }}
                                    />
                                </div>
                            </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        rows.map((row, index) => (
                            <TableRow>
                                <TableCell 
                                    align="center"
                                >
                                    {row.date}
                                </TableCell>
                                {/* <TableCell 
                                    align="center" 
                                    onClick={()=>{displayOrders(row.c1)}}
                                >
                                    <div style={{
                                        display:'flex', flexDirection:'column',
                                        alignItems: 'center'
                                    }}>
                                        {row.c1.length ? row.c1.length : ''}
                                        <Button
                                            onClick={()=>{displayOrders(row.c1)}}
                                            disabled={!(row.c1.length > 0)}
                                            color="secondary"
                                        >
                                            {t('view')}
                                        </Button>
                                        {
                                            index === 0
                                            &&
                                            <Button color="secondary" style={{marginLeft: 5}}  disabled={!(row.c1.length > 0)}>
                                                <CSVLink 
                                                    data={ row.c1 || [] }
                                                    style={{textDecoration: 'none', color: '#212121'}}
                                                >
                                                    {t('download_excel')}
                                                </CSVLink>
                                            </Button>
                                        }
                                    </div>
                                </TableCell> */}
                                {/* <TableCell 
                                    align="center" 
                                    onClick={()=>{displayOrders(row.c4)}}
                                >
                                    <div style={{
                                        display:'flex', flexDirection:'column',
                                        alignItems: 'center'
                                    }}>
                                        {row.c4.length ? row.c4.length : ''}
                                        <Button
                                            onClick={()=>{displayOrders(row.c4)}}
                                            disabled={!(row.c4.length > 0)}
                                            color="secondary"
                                        >
                                            {t('view')}
                                        </Button>
                                        {
                                            index === 0
                                            &&
                                            <Button color="secondary" style={{marginLeft: 5}}  disabled={!(row.c4.length > 0)}>
                                                <CSVLink 
                                                    data={ row.c4 || [] }
                                                    style={{textDecoration: 'none', color: '#212121'}}
                                                >
                                                    {t('download_excel')}
                                                </CSVLink>
                                            </Button>
                                        }
                                    </div>
                                </TableCell> */}
                                <TableCell 
                                    align="center" 
                                    onClick={()=>{displayOrders(rows[0].c2)}}
                                >
                                    <div style={{
                                        display:'flex', flexDirection:'column', alignItems: 'center'
                                    }}>
                                        {rows[0].c2.length ? rows[0].c2.length : ''}
                                        <Button
                                            onClick={()=>{displayOrders(rows[0].c2)}}
                                            disabled={!(rows[0].c2.length > 0)}
                                            color="secondary"
                                        >
                                            {t('view')}
                                        </Button>
                                        {/* {
                                            index === 0
                                            && */}
                                            <Button color="secondary" style={{marginLeft: 5}}  disabled={!(rows[0].c2.length > 0)}>
                                                <CSVLink 
                                                    data={ rows[0].c2 || [] }
                                                    style={{textDecoration: 'none', color: '#212121'}}
                                                >
                                                    {t('download_excel')}
                                                </CSVLink>
                                            </Button>
                                        {/* } */}
                                    </div>
                                </TableCell>
                                {/* <TableCell 
                                    align="center" 
                                    onClick={()=>{displayOrders(row.c3)}}
                                >
                                    <div style={{
                                        display:'flex', flexDirection:'column', alignItems: 'center'
                                    }}>
                                        {row.c3.length ? row.c3.length : ''}
                                        <Button
                                            onClick={()=>{displayOrders(row.c3)}}
                                            disabled={!(row.c3.length > 0)}
                                            color="secondary"
                                        >
                                            {t('view')}
                                        </Button>
                                        {
                                            index === 0
                                            &&
                                            <Button color="secondary" style={{marginLeft: 5}}  disabled={!(row.c3.length > 0)}>
                                                <CSVLink 
                                                    data={ row.c3 || [] }
                                                    style={{textDecoration: 'none', color: '#212121'}}
                                                >
                                                    {t('download_excel')}
                                                </CSVLink>
                                            </Button>
                                        }
                                    </div>
                                </TableCell> */}
                            </TableRow>
                        ))
                    }
                    </TableBody>
                </Table>
            </div>
        </React.Fragment>
    );
  }
