import { USER_LOGOUT } from "../actions/types"
import { setUserPreference } from "../utilities/common"

const initialState = {
	loading: false,
	error: false,
	isLoggedIn: false
}

function userReducer(state = initialState, action) {
	switch(action.type){
		case USER_LOGOUT: 
			setUserPreference(null, null, null, null, null, null)
			return {
				...state,
				isLoggedIn: false
			}
		default: {
			return state 
		}
	}
}

export default userReducer