import { useEffect, useState } from 'react';
import { Button, makeStyles, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useAlert } from '../../../utilities/hooks';
import FullscreenLoading from '../../../components/FullscreenLoading';
import { logInUser } from '../../../actions/driverAction';
import { driverLogin, searchDriver } from '../../../actions/t4aActions';
import { showAlertSnackbar } from '../../../reducers/uiSlice';

const useStyles = makeStyles((theme) => ({
    loginContainer: {
        width: 285,
        marginTop: 84,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    loginTitle: {
        width: 300,
        color: '#101820',
        fontSize: 17,
        fontWeight: 'bold',
        letterSpacing: 0
    },
    loginParam: {
        height: 24,
        color: '#636463',
        fontSize: 14,
        letterSpacing: 0
    },
    loginInput: {
        height: 48,
        width: 285
    },
    loginButton: {
        height: 41,
        width: 250,
        fontSize: '14px !important',
        borderRadius: 24.5,
        backgroundColor: '#F68D2E',
        marginTop: 50,
        '&:hover': {
            background: "#F68D2E",
        },
        letterSpacing: 0
    },
    errorMessage: {
        color: '#E91F1F',
        fontSize: 16,
        letterSpacing: 0
    }
}))

function LoginT4A() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [loginFail, setLoginFail] = useState(false);

    function handleLogin() {
        setLoading(true);
        dispatch(driverLogin(username, password))
            .then((res) => {
                setLoading(false);
                console.log(res);
                window.localStorage.setItem('driver_id', res.driver_id);
                dispatch(searchDriver(res.driver_id))
                    .then((res) => {
                        if (res === null) {
                            dispatch(showAlertSnackbar({ message: 'Could not find t4-A info', type: 'error' }));
                            setLoginFail(true);
                        } else {
                            history.push('/t4a-info');
                        }
                    })
            })
            .catch((error) => {
                
                setLoading(false);
                setLoginFail(true);
            })
    }

    useEffect(() => {
        setLoginFail(false);
    }, [])

    return (
        <div className={classes.loginContainer}>
            <img
                src='/images/group-copy.svg'
                style={{
                    height: 42,
                    width: 178,
                    marginBottom: 34
                }}
            />
            <div className={classes.loginTitle}>Sign in / Ouvrir une session</div>
            <div className={classes.loginParam} style={{ marginTop: 33 }}>Mobile number / Numéro de portable</div>
            <TextField
                variant="standard"
                className={classes.loginInput}
                autoComplete={'false'}
                onChange={(e) => setUsername(e.target.value)}
            />
            <div className={classes.loginParam} style={{ marginTop: 29 }}>Social Insurance Number / Numéro d’assurance sociale</div>
            <TextField
                variant="standard"
                className={classes.loginInput}
                autoComplete={'false'}
                onChange={(e) => setPassword(e.target.value)}
                style={{ marginTop: 20 }}
            />
            <div className={classes.errorMessage} hidden={!loginFail}>Invalid login information. Please check details and try again.</div>
            <div style={{
                textAlign: 'center'
            }}
            >
                <Button
                    className={classes.loginButton}
                    onClick={handleLogin}
                    disabled={loading}
                >
                    Sign in / Ouvrir une session
                </Button>
            </div>
            <FullscreenLoading open={loading} />
        </div>
    )
}
export default LoginT4A;