import _ from 'lodash'

export function sortKeys(obj) {
    let allKeys = Object.keys(obj);
    allKeys.sort();

    let objTemp = {};
    for (let key of allKeys) {
        objTemp[key] = obj[key]
    }
    return objTemp
}

export function textualizationStatus(status){
    if(status) {
        return 'YES';
    } else {
        return 'NO';
    }
}

export function stripIdFromStaffName(name){
    return name.replace(/^\d*\./, "")
}

export function getDistrictName(id){
    let localStorage = window.localStorage
    let districts = JSON.parse(localStorage.getItem('districts'))
    return districts[id] || "Unknown"
}

export function getAccountFilteredDistricts(accounts){
    let district = {}
    _.map(accounts, function(account){
        if (!district.hasOwnProperty(account.district)){
            district[account.district] = []
        }
        district[account.district].push(account)
    })
    return district
}

export function splitOrderByDistrict(orders){
    let result = {}
    _.map(orders, function(order){
        if (!result.hasOwnProperty(order.district)){
            result[order.district] = []
        }
        result[order.district].push(order)
    })

    return result
}

export function setUserPreference(authToken, userLevel, userCity, username, id, subjectId) {
    let localStorage = window.localStorage;
    localStorage.setItem('auth_token', authToken)
    localStorage.setItem('user_level', userLevel)
    localStorage.setItem('user_city', userCity)
    localStorage.setItem('username', username)
    localStorage.setItem('id', id)
    localStorage.setItem('subject_id', subjectId)
}

export function getUserPreference(){
    let localStorage = window.localStorage;
    return {
        authToken: localStorage.getItem('auth_token'),
        userLevel: localStorage.getItem('user_level'),
        userCity: localStorage.getItem('user_city'),
        username: localStorage.getItem('username')
    }
}

export function setAutoLatLngPref(set){
    let localStorage = window.localStorage;
    localStorage.setItem('auto_lat_lng', JSON.stringify(set))
}

export function setHideAssociatedOrders(set){
    let localStorage = window.localStorage;
    localStorage.setItem('hide_associated_orders', JSON.stringify(set))
}

export function getDriverOrderTrackingStatus(districtOrders = {}){
    let codes = _.map(districtOrders, (districtOrder) => {
        let _code = _.map(districtOrder, 'path_info')
        return _code
    })

    return _.uniq(_.flatten(codes))
}

export function getBranchById(id, branchList){
    return _.find(branchList, {'id': Number(id)})
}

export function getRefererFromId(batches, ids){
    let result = batches.filter(batch => ids.includes(batch.id)).map(batch => batch.referer)
    return result
}

export function getCheckedOrders(orderList, selectedIds){
    let orders = []
    _.map(orderList, (order, key)=>{
        let flatten = _.intersectionWith(_.flatten(_.values(order)), selectedIds, function(o, id){ return o.order_id === id})
        orders = _.union(orders, flatten)
    })
    return orders
}

export function getImageDimensions(uri){
    return new Promise((resolve, reject) => {
        let i = new Image()
        i.onload = () => {
            resolve({width: i.width, height: i.height, src: uri})
        }
        i.src = uri
    })
}

export function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

export function flattenOrders(orders = []){
    let result = []
    _.forEach(orders, (element)=>{
        let array = _.values(element).reduce((o, m) => [...o, ...m], [])
        result.push(...array)
    })
    return result
}