import { settlementServerInstance } from "../axios/instances";
import { setUserPreference } from "../utilities/common.js";
import { type } from './typesMobileUS';

export function getPaymentPeriod(driverId, start, end) {
    return function (dispatch) {
        dispatch({ type: type.GET_PAYMENT_PERIOD })
        return settlementServerInstance.post(`/getpaymentdetail`, {
            driverId: driverId,
            start: start,
            end: end
        })
            .then(({ data }) => {
                dispatch({
                    type: type.GET_PAYMENT_PERIOD_SUCCESS,
                    payload: data.retData
                })
                return Promise.resolve(data.retData);
            }).catch(error => {
                dispatch({
                    type: type.GET_PAYMENT_PERIOD_FAIL,
                    payload: error
                })
                return Promise.reject(error);
            });
    }
}

export function handleDetails(details) {
    return function (dispatch) {
        dispatch({ type: type.HANDLE_DETAILS })
        return (
            dispatch({
                type: type.HANDLE_DETAILS,
                payload: details
            })
        )
    }
}

export function getSettlementTimeList(driverId) {
    return function (dispatch) {
        dispatch({ type: type.GET_SETTLEMENT_TIME_LIST })
        return settlementServerInstance.get(`/getsettlementtimelist?driverId=${driverId}`)
            .then(({ data }) => {
                dispatch({
                    type: type.GET_SETTLEMENT_TIME_LIST_SUCCESS,
                    payload: data.retData
                })
                return Promise.resolve(data.retData);
            }).catch(error => {
                dispatch({
                    type: type.GET_SETTLEMENT_TIME_LIST_FAIL,
                    payload: error
                })
                return Promise.reject(error);
            });
    }
}

export function getCurrentPaymentPeriod(driverId, start, end) {
    return function (dispatch) {
        dispatch({ type: type.GET_CURRENT_PAYMENT_PERIOD })
        return settlementServerInstance.post(`/getcurrentpayment`, {
            driverId: driverId,
            start: start,
            end: end
        })
            .then(({ data }) => {
                dispatch({
                    type: type.GET_CURRENT_PAYMENT_PERIOD_SUCCESS,
                    payload: data.retData
                })
                return Promise.resolve(data.retData);
            }).catch(error => {
                dispatch({
                    type: type.GET_CURRENT_PAYMENT_PERIOD_FAIL,
                    payload: error
                })
                return Promise.reject(error);
            });
    }
}

export function getCurrentPayment(driverId, start, end) {
    return function (dispatch) {
        dispatch({ type: type.GET_CURRENT_PAYMENT })
        return settlementServerInstance.post(`/getcurrentpayment`, {
            driverId: driverId,
            start: start,
            end: end
        })
            .then(({ data }) => {
                dispatch({
                    type: type.GET_CURRENT_PAYMENT_SUCCESS,
                    payload: data.retData
                })
                return Promise.resolve(data.retData);
            }).catch(error => {
                dispatch({
                    type: type.GET_CURRENT_PAYMENT_FAIL,
                    payload: error
                })
                return Promise.reject(error);
            });
    }
}

export function logInDriverUS(username, password) {
    return function (dispatch) {
        dispatch({ type: type.USER_LOGIN })
        return settlementServerInstance.post(`/login`, {
            username: username,
            password: password
        })
            .then(({ data }) => {
                dispatch({
                    type: type.USER_LOGIN_SUCCESS,
                    payload: data.retData
                })
                setUserPreference(data.retData.token, data.retData.level, data.retData.city_id, data.retData.username, data.retData.id, data.retData.subjectId)
                settlementServerInstance.defaults.headers.common['Authorization'] = `Bearer ${data.retData.token}`
                return Promise.resolve(data.retData);
            }).catch(error => {
                dispatch({
                    type: type.USER_LOGIN_FAIL,
                    payload: error
                })
                return Promise.reject(error);
            });
    }
}