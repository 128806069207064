import { useEffect, useState } from "react";
import IncomeDetails from "../../../components/DriverMobile/IncomeDetails";

function IncomeStatements() {
    return (
        <div>
            <IncomeDetails />
        </div>
    )
}
export default IncomeStatements;