import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';
import { Button, DialogContent, DialogTitle, TableBody, TableCell, TableContainer, TableHead, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Dialog } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const StyledTableCellTitle = withStyles((theme) => ({
    body: {
        backgroundColor: "#4FC3F7"
    },
}))(TableCell);
const StyledTableCell = withStyles((theme) => ({
    body: {
        backgroundColor: "#E1F5FE"
    },
}))(TableCell);

export default function SalaryTableMobile(props) {
    const { salaryInfo, identity, driverInfo, salarylist } = props;
    const {t} = useTranslation('common');

    const [driver, setDriver] = useState('');
    const [rows, setRows] = useState([]);
    const [salaryList, setSalaryList] = useState([]);
    const [salaryListOpen, setSalaryListOpen] = useState(false);

    const handleSalaryListClose = () => {
        setSalaryListOpen(false);
    };

    function createRows(){
        return salaryList.map((row) => {
            const {tno, ...rest} = row
            return {id: tno, ...rest}
        })
    }

    useEffect(() => {
        if(salarylist) {
            if(salarylist.length > 0) {
                setSalaryList(salarylist);
            }
        }
    }, [salarylist])
    useEffect(() => {
        if(salaryInfo) {
            setRows(salaryInfo);
        }
    }, [salaryInfo])
    useEffect(() => {
        if(driverInfo) {
            if(driverInfo[0]) {
                setDriver(driverInfo[0].name);
            }
        }
    }, [driverInfo])

    const columns = [
        { 
            field: 'id', 
            headerName: t('order_sn'), 
            width: 200
        },
        { 
            field: 'amount', 
            headerName: t('price'), 
            width: 150
        },
        { 
            field: 'zip', 
            headerName: t('zip'), 
            width: 150
        },
    ]
    

  return (
        <>
            <Dialog fullWidth maxWidth='lg' onClose={handleSalaryListClose} aria-labelledby="customized-dialog-title" open={salaryListOpen}>
                <DialogTitle id="customized-dialog-title" onClose={handleSalaryListClose}>
                    {t('salary_list')}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <TableContainer>
                            <div style={{ height: 500, width: '100%' }}>
                                <DataGrid
                                    rows={createRows()}
                                    columns={columns}
                                    pageSize={8}
                                    rowsPerPageOptions={[5]}
                                    disableSelectionOnClick
                                />
                            </div>
                        </TableContainer>
                    </Typography>
                </DialogContent>
            </Dialog>
            <div hidden={!identity}>
                {
                    driver &&
                    <div
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop: 20,
                            width: 'fit-content',
                            padding: 10,
                            backgroundColor: '#9CCC65',
                            border: '1px solid #558B2F',
                            borderRadius: 5
                        }}
                    >{t('driver')}{driver}</div>
                }
            </div>
            <div 
                style={{
                    marginLeft: 'auto', 
                    marginRight: 'auto', 
                    maxWidth: 'fit-content',
                    marginTop: 20
                }}
            >
                <Table>
                    <TableBody>
                        {
                            rows &&
                            <div>
                                <TableRow>
                                    <StyledTableCellTitle>{t('amount')}</StyledTableCellTitle>
                                    <StyledTableCell>{rows['amt']}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCellTitle>{t('orders_num')}</StyledTableCellTitle>
                                    <StyledTableCell 
                                    align='center'
                                    onClick={() => {
                                        if(salaryList.length > 0) {
                                            setSalaryListOpen(true);
                                        }
                                    }}
                                    style={{display: 'flex', alignItems: 'center'}}
                                >
                                    {rows['ct']}
                                    <Button color='primary'>{t('view')}</Button>
                                </StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCellTitle>{t('delivered_num')}</StyledTableCellTitle>
                                    <StyledTableCell>{rows['203ct']}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCellTitle>{t('overweight_num')}</StyledTableCellTitle>
                                    <StyledTableCell>{rows['ow_ct']}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCellTitle>{t('overweight_amount')}</StyledTableCellTitle>
                                    <StyledTableCell>{rows['ow_amt']}</StyledTableCell>
                                </TableRow>
                                {/* <TableRow>
                                    <StyledTableCellTitle>{t('salary_type')}</StyledTableCellTitle>
                                    <StyledTableCell>{rows['sa_type']}</StyledTableCell>
                                </TableRow> */}
                                <TableRow>
                                    <StyledTableCellTitle>{t('minus_add')}</StyledTableCellTitle>
                                    <StyledTableCell>{rows['amt_adj']}</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCellTitle>{t('annual_income')}</StyledTableCellTitle>
                                    <StyledTableCell>{rows['cy_amt']}</StyledTableCell>
                                </TableRow>
                            </div>
                        }
                    </TableBody>
                </Table>
            </div>
        </>
    );
}