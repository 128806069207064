import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserPreference } from "../../utilities/common";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FullscreenLoading from '../../components/FullscreenLoading';
import { showAlertSnackbar } from '../../reducers/uiSlice';
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Language } from '@material-ui/icons';
import { changeLanguagePref, getLanguageString } from '../../utilities/language';

import _ from 'lodash'
import { useAlert } from '../../utilities/hooks';
import { logInUser } from '../../actions/driverAction';

const useStyles = makeStyles((theme)=> ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        height: '100vh'
    },
    loginForm: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loginButton: {
        marginTop: '20px'
    },
    background: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: -1,
        filter: 'blur(15px)'
    },
    textField: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    optionsBar: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        right: 0,
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
        },
    }
}))


function Login(){
    const classes = useStyles();
    const {t, i18n} = useTranslation('common');

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [userInfo, setUserInfo] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);

    const dispatch = useDispatch();
    const alertDialog = useAlert();
    const history = useHistory();

    const user = useSelector(state => state.user);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    useEffect(() => {
        if(width <= 768) {
            window.localStorage.setItem("isMobile", true);
        } else {
            window.localStorage.setItem("isMobile", false);
        }
    }, [width])

    return (
        <div className={classes.root}>
            <Box className={classes.optionsBar}>
                <Button onClick={handleLanguageChange} startIcon={<Language/>}>{getLanguageString(i18n.language)}</Button>
            </Box>
            <Container maxWidth="sm">
                <Box className={classes.loginForm}>
                    <form style={{width: "80%", margin: 'auto'}}>
                        <div>{t('title')}</div>
                        <TextField 
                            className={classes.textField} 
                            label={t('username')} 
                            fullWidth 
                            onChange={(e)=>setUsername(e.target.value)}
                        />
                        <TextField type='password' className={classes.textField} label={t('password')} fullWidth onChange={(e)=>setPassword(e.target.value)}/>
                        <Button type="submit" className={classes.loginButton} variant="contained" color="primary" fullWidth onClick={handleLogin}>
                            {t('login')}
                        </Button>
                        <Box 
                            style={{ 
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginTop: "20px", 
                                display: "flex", 
                                flexDirection: "column",
                                alignItems: 'center',
                                textAlign: 'center'
                            }}
                        >
                            <Box>
                                {t('external_link')}
                            </Box>
                            <Box style={{marginTop:'10px'}}>
                                <Link>
                                    {process.env.REACT_APP_DRIVER_FRONTEND_URL}
                                </Link>
                            </Box>
                        </Box>
                    </form>
                </Box>
            </Container>
            <FullscreenLoading open={user.loading}/>
        </div>
    )

    function handleLogin(event) {
        event.preventDefault()
        if (username.length === 0 || password.length === 0){
            dispatch(showAlertSnackbar({title: "Username or password cannot be empty!"}))
            return;
        }
        dispatch(logInUser(username, password))
        .then(() =>{
            history.push('/');
        })
        .catch((error)=>{
            alertDialog.addAlertDialog("LOGIN", error.message)
            alertDialog.showAlert()
        })

        // var xmlhttp;
        // if (window.XMLHttpRequest) {
        //     xmlhttp=new XMLHttpRequest();
        // } else {
        //     xmlhttp=new ActiveXObject("Microsoft.XMLHTTP");
        // }
        // xmlhttp.onreadystatechange = function() {
        //     if (JSON.parse(xmlhttp.responseText).status==='SUCCESS') {
        //         const response = JSON.parse(xmlhttp.responseText);
        //         setUserInfo(response.data);
        //         setUserPreference(response.data.token, response.data.userinfo.level, response.data.userinfo.city_id, response.data.userinfo.username, response.data.userinfo.level, response.data.userinfo.id)
        //         history.push('/');
        //     } else {
        //         alertDialog.addAlertDialog("LOGIN", 'Wrong user info');
        //         alertDialog.showAlert();
        //     }
        // }
        // xmlhttp.open("GET",`https://acctdev.uniexpress.ca/prod/uni2/public/login?username=${username}&password=${password}`,true);
        // xmlhttp.send();
    }
    function handleLanguageChange(){
        if(i18n.language === "en"){
            changeLanguagePref(i18n, "cn")
        } else {
            changeLanguagePref(i18n, "en")
        }
    }
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
}

export default Login