import { makeStyles } from "@material-ui/core"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PopUp from "../../T4A/Popup";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getOrderAndSalary, getOrderAndSalaryDetail } from "../../../actions/driverMobileActions";
import FullscreenLoading from '../../FullscreenLoading';

const useStyles = makeStyles((theme) => ({
    overviewTable: {
        marginTop: 22,
        borderTop: '3px solid #0D1923'
    },
    overviewTableRow: {
        backgroundColor: 'rgba(235,236,239,0.8)',
        padding: 11
    },
    overviewTableDetail: {
        display: 'flex',
        justifyContent: 'space-between',
        width: 290,
        paddingBottom: 7
    },
    viewDetails: {
        marginTop: 32,
        marginLeft: 160,
        display: 'flex',
        alignItems: 'center'
    },
    viewDetailsText: {
        width: 129,
        color: '#F68D2E',
        fontSize: 18,
        fontWeight: 500,
        letterSpacing: 0,
        textAlign: 'center'
    },
    incomeTitle: {
        color: '#F68D2E',
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: 0
    },
    incomeAmount: {
        color: '#F68D2E',
        fontSize: 40,
        fontWeight: 600,
        letterSpacing: 0
    },
}))

function IncomeCompleteTable(props) {
    const classes = useStyles();
    const {t} = useTranslation('incomeOverview');
    const history = useHistory();
    const dispatch = useDispatch();

    const [popupOpen, setPopupOpen] = useState(false);
    const [style, setStyle] = useState(1);
    const [salaryInfo, setSalaryInfo] = useState({});
    const [loading, setLoading] = useState(false);

    function clickPopupOpen(style) {
        setPopupOpen(true);
        setStyle(style);
    }
    function handlePopupClose() {
        setPopupOpen(false);
    }
    function viewDetails() {
        let startTime = 0;
        let endTime = 0;
        if(props.type === 'income-overview') {
            const lastStart = window.localStorage.getItem('lastStart');
            const lastEnd = window.localStorage.getItem('lastEnd');
            startTime = window.localStorage.getItem('lastStartTime');
            endTime = window.localStorage.getItem('lastEndTime');
            window.localStorage.setItem('current-start', lastStart);
            window.localStorage.setItem('current-end', lastEnd);
            window.localStorage.setItem('current-start-time', startTime);
            window.localStorage.setItem('current-end-time', endTime);
        } else if(props.type === 'income-statement') {
            startTime = window.localStorage.getItem('current-start-time');
            endTime = window.localStorage.getItem('current-end-time');
        }
        history.push('/driverMobile/deliveryStatements');
    }

    useEffect(() => {
        if(props.salaryInfo) {
            if(props.salaryInfo.length > 0) {
                setSalaryInfo(props.salaryInfo[0]);
            }
        }
    }, [props.salaryInfo])
    useEffect(() => {
        if(props.startTime && props.endTime) {
            setLoading(true);
            dispatch(getOrderAndSalary(props.startTime, props.endTime))
            .then((res) => {
                window.localStorage.setItem('email', res.driverinfo[0].email);
                setLoading(false);
                setSalaryInfo(res.data[0]);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            })
        }
    }, [props.startTime, props.endTime])

    return (
        <div>
            <div style={{display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginTop: 33}}>
                    <span className={classes.incomeTitle}>{t('total_income')}</span>
                {
                    salaryInfo.amt ?
                    <span className={classes.incomeAmount}>
                        $ {salaryInfo.amt}
                    </span>
                    :
                    <span className={classes.incomeAmount}>
                        $0
                    </span>
                }
            </div>
            <div className={classes.overviewTable}>
                <div className={classes.overviewTableRow}>
                    <div className={classes.overviewTableDetail} style={{borderBottom: '1px solid #8A8B8A'}}>
                        <div>{t('completed_orders')}</div>
                        <div>{salaryInfo.completed}</div>
                    </div>
                </div>
                {/* <div className={classes.overviewTableRow}>
                    <div className={classes.overviewTableDetail} style={{borderBottom: '1px solid #8A8B8A'}}>
                        <div>{t('incompleted_orders')}</div>
                        <div>{salaryInfo.incompleted}</div>
                    </div>
                </div> */}
                <div className={classes.overviewTableRow}>
                    <div className={classes.overviewTableDetail} style={{borderBottom: '1px solid #8A8B8A'}}>
                        <div>{t('paid_orders')}</div>
                        <div>{salaryInfo.paid}</div>
                    </div>
                </div>
                <div className={classes.overviewTableRow}>
                    <div className={classes.overviewTableDetail} style={{borderBottom: '1px solid #8A8B8A'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div>{t('unpaid_orders')}</div>
                            <img 
                                src='/images/question-mark.svg' 
                                style={{
                                    height: 20,
                                    width: 20,
                                    marginLeft: 7.5
                                }}
                                onClick={() => clickPopupOpen(1)}
                            />
                        </div>
                        <div>{salaryInfo.unpaid}</div>
                    </div>
                </div>
                <div className={classes.overviewTableRow}>
                    <div className={classes.overviewTableDetail} style={{borderBottom: '1px solid #8A8B8A'}}>
                        <div>{t('overweight_orders')}</div>
                        <div>{salaryInfo.owerweight}</div>
                    </div>
                </div>
                <div className={classes.overviewTableRow}>
                    <div className={classes.overviewTableDetail} style={{borderBottom: '1px solid #8A8B8A'}}>
                        <div>{t('overweight_amount')}</div>
                        <div>{salaryInfo.owamt}</div>
                    </div>
                </div>
                <div className={classes.overviewTableRow}>
                    <div className={classes.overviewTableDetail}>
                        <div>{t('allowance_deduction')}</div>
                        <div>{salaryInfo.amt_adj}</div>
                    </div>
                </div>
                <div className={classes.viewDetails} onClick={viewDetails}>
                    <div className={classes.viewDetailsText}>{t('delivery_details')}</div>
                    <ArrowRightAltIcon style={{color: "#F68D2E"}}/>
                </div>
                <PopUp style={style} open={popupOpen} handlePopupClose={handlePopupClose} type='income' timeStart={window.localStorage.getItem('current-start-time')} timeEnd={window.localStorage.getItem('current-end-time')}/>
            </div>
            <FullscreenLoading open={loading}/>
        </div>
    )
}
export default IncomeCompleteTable;