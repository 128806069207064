export const MarkerTypes = {
    DRIVER: "driver",
    WAREHOUSE: "warehouse",
    APARTMENT: "apartment",
    NORMAL: "normal",
    OTHER: "other"
}

export const MarkerIconPath = {
    DRIVER: "./images/markers/car.png",
    WAREHOUSE: "./images/markers/warehouse.png",
    DEFAULT: "./images/markers/default.png",
}

export const ErrorTypes = {
    JSON: 0,
    HTML: 1
}

export const MapModes = {
    NONE: "none",
    SELECT_START: "select_start",
    SELECT_END: "select_end"
}