import { 
    USER_LOGIN,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    GET_ORDER_COUNT,
    GET_ORDER_COUNT_SUCCESS,
    GET_ORDER_COUNT_FAIL,
    GET_SALARY,
    GET_SALARY_SUCCESS,
    GET_SALARY_FAIL
 } from '../actions/types';

const initialState = {
    userLoginLoading: false,
    userLogin: {},
    userLoginError: false,
    getOrderCountLoading: false,
    getOrderCount: {},
    getOrderCountError: false,
    getSalaryLoading: false,
    getSalary: {},
    getSalaryError: false,
}

export default function hdReducer(state = initialState, action){
    switch(action.type){
        case GET_SALARY:
            return {
                ...state,
                getSalaryLoading: true,
            }
        case GET_SALARY_SUCCESS:
            return {
                ...state,
                getSalary: action.payload,
                getSalaryLoading: false
            }
        case GET_SALARY_FAIL:
            return {
                ...state,
                getSalaryLoading: false,
                getSalaryError: action.payload
            }
        case GET_ORDER_COUNT:
            return {
                ...state,
                getOrderCountLoading: true,
            }
        case GET_ORDER_COUNT_SUCCESS:
            return {
                ...state,
                getOrderCount: action.payload,
                getOrderCountLoading: false
            }
        case GET_ORDER_COUNT_FAIL:
            return {
                ...state,
                getOrderCountLoading: false,
                getOrderCountError: action.payload
            }
        case USER_LOGIN:
            return {
                ...state,
                userLoginLoading: true,
            }
        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                userLogin: action.payload,
                userLoginLoading: false
            }
        case USER_LOGIN_FAIL:
            return {
                ...state,
                userLoginLoading: false,
                userLoginError: action.payload
            }
        default:
            return state;
    }
}