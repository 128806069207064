import { acctServerInstance } from "../axios/instances";
import { setUserPreference } from "../utilities/common.js";
import { 
    USER_LOGIN_MOBILE,
    USER_LOGIN_MOBILE_SUCCESS,
    USER_LOGIN_MOBILE_FAIL,
    GET_ORDER_AND_SALARY,
    GET_ORDER_AND_SALARY_SUCCESS,
    GET_ORDER_AND_SALARY_FAIL,
    GET_ORDER_AND_SALARY_DETAIL,
    GET_ORDER_AND_SALARY_DETAIL_SUCCESS,
    GET_ORDER_AND_SALARY_DETAIL_FAIL,
    GET_ORDER_AND_SALARY_EMAIL,
    GET_ORDER_AND_SALARY_EMAIL_SUCCESS,
    GET_ORDER_AND_SALARY_EMAIL_FAIL,
    GET_ORDER_AND_SALARY_DETAIL_LA,
    GET_ORDER_AND_SALARY_DETAIL_LA_SUCCESS,
    GET_ORDER_AND_SALARY_DETAIL_LA_FAIL,
    GET_SALARY_ZIP_COUNT,
    GET_SALARY_ZIP_COUNT_SUCCESS,
    GET_SALARY_ZIP_COUNT_FAIL
} from './typesMobile';

export function getSalaryZipCount(time1, time2){
    return function(dispatch){
        dispatch({type: GET_SALARY_ZIP_COUNT})
        return acctServerInstance.get(`/getsalaryzipcount?time1=${time1}&time2=${time2}`)
            .then(({data}) => {
                dispatch({
                    type: GET_SALARY_ZIP_COUNT_SUCCESS,
                    payload: data.data
                })
                return Promise.resolve(data.data);
            }).catch(error => {
                dispatch({
                    type: GET_SALARY_ZIP_COUNT_FAIL,
                    payload: error
                })
                return Promise.reject(error);
            });
    }
}

export function getOrderAndSalaryDetailLA(time1, time2){
    return function(dispatch){
        dispatch({type: GET_ORDER_AND_SALARY_DETAIL_LA})
        return acctServerInstance.get(`/getorderandsalarydetail_1?time1=${time1}&time2=${time2}`)
            .then(({data}) => {
                dispatch({
                    type: GET_ORDER_AND_SALARY_DETAIL_LA_SUCCESS,
                    payload: data.data
                })
                return Promise.resolve(data.data);
            }).catch(error => {
                dispatch({
                    type: GET_ORDER_AND_SALARY_DETAIL_LA_FAIL,
                    payload: error
                })
                return Promise.reject(error);
            });
    }
}

export function getOrderAndSalaryEmail(email, save, time1, time2){
    return function(dispatch){
        dispatch({type: GET_ORDER_AND_SALARY_EMAIL})
        return acctServerInstance.get(`/getorderandsalaryemail?email=${email}&saveemail=${save}&time1=${time1}&time2=${time2}`)
            .then(({data}) => {
                dispatch({
                    type: GET_ORDER_AND_SALARY_EMAIL_SUCCESS,
                    payload: data
                })
                return Promise.resolve(data);
            }).catch(error => {
                dispatch({
                    type: GET_ORDER_AND_SALARY_EMAIL_FAIL,
                    payload: error
                })
                return Promise.reject(error);
            });
    }
}

export function getOrderAndSalaryDetail(time1, time2){
    return function(dispatch){
        dispatch({type: GET_ORDER_AND_SALARY_DETAIL})
        return acctServerInstance.get(`/getorderandsalarydetail?time1=${time1}&time2=${time2}`)
            .then(({data}) => {
                dispatch({
                    type: GET_ORDER_AND_SALARY_DETAIL_SUCCESS,
                    payload: data.data
                })
                return Promise.resolve(data.data);
            }).catch(error => {
                dispatch({
                    type: GET_ORDER_AND_SALARY_DETAIL_FAIL,
                    payload: error
                })
                return Promise.reject(error);
            });
    }
}

export function getOrderAndSalary(time1, time2){
    return function(dispatch){
        dispatch({type: GET_ORDER_AND_SALARY})
        return acctServerInstance.get(`/getorderandsalary?time1=${time1}&time2=${time2}`)
            .then(({data}) => {
                dispatch({
                    type: GET_ORDER_AND_SALARY_SUCCESS,
                    payload: data
                })
                return Promise.resolve(data);
            }).catch(error => {
                dispatch({
                    type: GET_ORDER_AND_SALARY_FAIL,
                    payload: error
                })
                return Promise.reject(error);
            });
    }
}

export function logInUserMobile(username, password){
    return function(dispatch){
        dispatch({type: USER_LOGIN_MOBILE})
        return acctServerInstance.post(`/login`, {
            username: username,
            password: password
        })
            .then(({data}) => {
                dispatch({
                    type: USER_LOGIN_MOBILE_SUCCESS,
                    payload: data.data
                })
                setUserPreference(data.data.token, data.data.userinfo.level, data.data.userinfo.city_id, data.data.userinfo.username, data.data.userinfo.id, data.data.userinfo.subjectId)
                window.localStorage.setItem('driver_id', data.data.userinfo.id);
                window.localStorage.setItem('subject_id', data.data.userinfo.subjectId);
                acctServerInstance.defaults.headers.common['Authorization'] = `Bearer ${data.data.token}`
                return Promise.resolve(data.data);
            }).catch(error => {
                dispatch({
                    type: USER_LOGIN_MOBILE_FAIL,
                    payload: error
                })
                return Promise.reject(error);
            });
    }
}