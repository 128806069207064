import { acctServerInstance, shangjiaServerInstance } from "../axios/instances";
import { setUserPreference } from "../utilities/common.js";
import { 
    USER_LOGIN,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    GET_ORDER_COUNT,
    GET_ORDER_COUNT_SUCCESS,
    GET_ORDER_COUNT_FAIL,
    GET_SALARY,
    GET_SALARY_SUCCESS,
    GET_SALARY_FAIL,
    GET_DRIVERLIST,
    GET_DRIVERLIST_SUCCESS,
    GET_DRIVERLIST_FAIL,
    GET_LAGET_SALARY,
    GET_LAGET_SALARY_SUCCESS,
    GET_LAGET_SALARY_FAIL,
    GET_LAGET_SALARY_LA,
    GET_LAGET_SALARY_LA_SUCCESS,
    GET_LAGET_SALARY_LA_FAIL
} from './types';

export function getLagetSalaryLA(driverId, period) {
    return function(dispatch){
        dispatch({type: GET_LAGET_SALARY_LA})
        return acctServerInstance.get(`/lagetsalary_1?driverid=${driverId}&pre=${period}`)
            .then(({data}) => {
                dispatch({
                    type: GET_LAGET_SALARY_LA_SUCCESS,
                    payload: data
                })
                return Promise.resolve(data);
            }).catch(error => {
                dispatch({
                    type: GET_LAGET_SALARY_LA_FAIL,
                    payload: error
                })
                return Promise.reject(error);
            });
    }
}

export function getLagetSalary(driverId, period) {
    return function(dispatch){
        dispatch({type: GET_LAGET_SALARY})
        return acctServerInstance.get(`/lagetsalary?driverid=${driverId}&pre=${period}`)
            .then(({data}) => {
                dispatch({
                    type: GET_LAGET_SALARY_SUCCESS,
                    payload: data
                })
                return Promise.resolve(data);
            }).catch(error => {
                dispatch({
                    type: GET_LAGET_SALARY_FAIL,
                    payload: error
                })
                return Promise.reject(error);
            });
    }
}

export function getDriverlist(){
    return function(dispatch){
        dispatch({type: GET_DRIVERLIST})
        return acctServerInstance.get(`/query/driverlist`, {
            timeout: 60000 * 10
        })
            .then(({data}) => {
                dispatch({
                    type: GET_DRIVERLIST_SUCCESS,
                    payload: data
                })
                return Promise.resolve(data.data);
            }).catch(error => {
                dispatch({
                    type: GET_DRIVERLIST_FAIL,
                    payload: error
                })
                return Promise.reject(error);
            });
    }
}

export function getSalary(driverId, period){
    return function(dispatch){
        dispatch({type: GET_SALARY})
        return acctServerInstance.get(`/getsalary?driverid=${driverId}&pre=${period}`)
            .then(({data}) => {
                dispatch({
                    type: GET_SALARY_SUCCESS,
                    payload: data
                })
                return Promise.resolve(data);
            }).catch(error => {
                dispatch({
                    type: GET_SALARY_FAIL,
                    payload: error
                })
                return Promise.reject(error);
            });
    }
}

export function getOrderCount(driverId, period){
    return function(dispatch){
        dispatch({type: GET_ORDER_COUNT})
        return acctServerInstance.get(`/getordercount?driverid=${driverId}&pre=${period}`)
            .then(({data}) => {
                dispatch({
                    type: GET_ORDER_COUNT_SUCCESS,
                    payload: data
                })
                return Promise.resolve(data);
            }).catch(error => {
                dispatch({
                    type: GET_ORDER_COUNT_FAIL,
                    payload: error
                })
                return Promise.reject(error);
            });
    }
}

export function logInUser(username, password){
    return function(dispatch){
        dispatch({type: USER_LOGIN})
        return acctServerInstance.post(`/login`, {
            username: username,
            password: password
        })
            .then(({data}) => {
                dispatch({
                    type: USER_LOGIN_SUCCESS,
                    payload: data.data
                })
                setUserPreference(data.data.token, data.data.userinfo.level, data.data.userinfo.city_id, data.data.userinfo.username, data.data.userinfo.id, data.data.userinfo.subjectId)
                acctServerInstance.defaults.headers.common['Authorization'] = `Bearer ${data.data.token}`
                return Promise.resolve(data.data);
            }).catch(error => {
                dispatch({
                    type: USER_LOGIN_FAIL,
                    payload: error
                })
                return Promise.reject(error);
            });
    }
}