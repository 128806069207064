import { setUserPreference } from "../utilities/common.js";

export function userLogout(driverIncome){
    window.localStorage.removeItem("auth_token");
    if(driverIncome) {
        window.location.href = '/driverMobile/login';
    } else {
        window.location.reload();
    }
}
