import {combineReducers} from 'redux';

import uiSlice from './reducers/uiSlice';
import userReducer from './reducers/userReducer';
import driverReducer from './reducers/driverReducer';
import t4aReducer from './reducers/t4aReducer';
import driverUSReducer from './reducers/driverUSReducer';

const rootReducer = combineReducers({
    user: userReducer,
    ui: uiSlice,
    driverReducer: driverReducer,
    t4a: t4aReducer,
    driverUSReducer: driverUSReducer
});

export default rootReducer;
