import { makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getOrderAndSalaryDetail, getOrderAndSalaryDetailLA } from '../../../actions/driverMobileActions';
import FullscreenLoading from '../../FullscreenLoading';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme)=> ({
    root: {
        width: 313,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    item: {
        color: '#101820',
        fontSize: 18,
        fontWeight: 'bold',
        letterSpacing: 0,
        marginTop: 18,
        paddingBottom: 6,
        width: 290,
        borderBottom: '2px solid #0D1923'
    },
    itemClicked: {
        borderRadius: 4,
        backgroundColor: 'rgba(235,236,239,0.8)',
        paddingLeft: 13.5,
        paddingTop: 8,
        paddingBottom: 10,
        paddingRight: 12
    },
    itemDateClicked: {
        color: '#F68D2E',
        fontSize: 18,
        letterSpacing: 0,
        paddingBottom: 7,
        borderBottom: '1.5px solid #F68D2E'
    },
    itemContentClicked: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 10,
        paddingBottom: 5
    }
}))

const AccordionStyled = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: 'none',
    '&:before': {
        display: 'none',
    }
}));
const MuiAccordionStyled = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    '& .MuiAccordionSummary-root': {
        minHeight: '25px !important',
        maxHeight: '25px !important'
    },
    border: 'none',
    '&:before': {
        display: 'none',
    }
}));
const AccordionSummaryStyled = styled((props) => (
    <MuiAccordionSummary {...props}/>
))(({ theme }) => ({
    border: 'none',
    // flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)'
    },
    color: '#101820',
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 0,
    marginTop: 18,
    paddingBottom: 6,
    width: 290,
    borderBottom: '2px solid #0D1923',
    padding: '0px !important'
}));
const MuiAccordionSummaryStyled = styled((props) => (
    <MuiAccordionSummary {...props}/>
))(({ theme }) => ({
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)'
    },
    '& .MuiAccordionSummary-content': {
        margin: '0 !important'
    },
    padding: 0,
    backgroundColor: '#eff0f2'
}));
const AccordionDetailsStyled = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    border: 'none',
    paddingLeft: '0px !important'
}));
const MuiAccordionDetailsStyled = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    border: 'none',
    backgroundColor: '#eff0f2',
    padding: 0
}));
  

function IncomeDeliveryStatements(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation('incomeOverview');

    const details = useSelector(state => state.driverUSReducer.details);

    const [loading, setLoading] = useState(false);
    const [salaryList, setSalaryList] = useState([]);

    function transferMilage(meters) {
        return parseFloat(meters * 0.000621371192).toFixed(2);
    }

    useEffect(() => {
        if (props.startTime && props.endTime && process.env.REACT_APP_COUNTRY === 'CA') {
            setLoading(true);
            let getOrderAndSalaryDetailFun = process.env.REACT_APP_COUNTRY === 'US' ? getOrderAndSalaryDetailLA : getOrderAndSalaryDetail;
            dispatch(getOrderAndSalaryDetailFun(props.startTime, props.endTime))
            .then((res) => {
                setLoading(false);
                setSalaryList(res);
            })
            .catch((err) => {
                setLoading(false);
            })
        }
    }, [props.startTime, props.endTime])

    return (
        <div className={classes.root}>
            {
                process.env.REACT_APP_COUNTRY === 'CA' &&
                salaryList?.length > 0 &&
                salaryList.map((salary, index) => {
                    return (
                        <AccordionStyled>
                            <AccordionSummaryStyled
                                expandIcon={<ArrowDropDownIcon />}
                            >
                            <div>{salary.date}</div>
                            </AccordionSummaryStyled>
                            <AccordionDetailsStyled>
                                <div className={classes.itemClicked}>
                                    <div className={classes.itemContentClicked} style={{borderBottom: '1px solid #8A8B8A'}}>
                                        <div>{t('daily_new')}</div>
                                        <div>{salary.new}</div>
                                    </div>
                                    <div className={classes.itemContentClicked} style={{borderBottom: '1px solid #8A8B8A'}}>
                                        <div>{t('daily_completed')}</div>
                                        <div>{salary.completed}</div>
                                    </div>
                                </div>
                            </AccordionDetailsStyled>
                        </AccordionStyled>
                    )
                })
            }
            {
                process.env.REACT_APP_COUNTRY === 'US' &&
                Object.keys(details)?.length > 0 &&
                Object.keys(details).map((key, index) => {
                    return (
                        <AccordionStyled>
                            <AccordionSummaryStyled
                                expandIcon={<ArrowDropDownIcon />}
                            >
                                <div>{key}</div>
                            </AccordionSummaryStyled>
                            <AccordionDetailsStyled>
                                <div className={classes.itemClicked}>
                                    <div className={classes.itemContentClicked} style={{ borderBottom: '1px solid #8A8B8A' }}>
                                        <div>{t('daily_completed')}</div>
                                        <div>{details[key].complete}</div>
                                    </div>
                                    <div className={classes.itemContentClicked} style={{ borderBottom: '1px solid #8A8B8A' }}>
                                        <div>{t('smaller')}</div>
                                        <div>{details[key].lt}</div>
                                    </div>
                                    <div className={classes.itemContentClicked} style={{ borderBottom: '1px solid #8A8B8A' }}>
                                        <div>{t('between')}</div>
                                        <div>{details[key].between}</div>
                                    </div>
                                    <div className={classes.itemContentClicked} style={{ borderBottom: '1px solid #8A8B8A' }}>
                                        <div>{t('larger')}</div>
                                        <div>{details[key].gt}</div>
                                    </div>
                                    <div className={classes.itemContentClicked} style={{ borderBottom: '1px solid #8A8B8A' }}>
                                        <div>{t('overdue')}</div>
                                        <div>{details[key].overdue}</div>
                                    </div>
                                </div>
                            </AccordionDetailsStyled>
                        </AccordionStyled>
                    )
                })
            }
            <FullscreenLoading open={loading}/>
        </div>
    )
}
export default IncomeDeliveryStatements;