import PopUp from "../../T4A/Popup";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentPayment, getPaymentPeriod, handleDetails } from "../../../actions/driverMobileActionsUS";
import { showAlertSnackbar } from "../../../reducers/uiSlice";
import { sortKeys } from "../../../utilities/common";

const useStyles = makeStyles((theme)=> ({
    root: {
        width: 315,
        marginTop: 22,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    title: {
        color: '#101820',
        fontSize: 24,
        fontWeight: 'bold',
        letterSpacing: 0,
        marginTop: 44
    },
    subTitle: {
        backgroundColor: '#EBECEF',
        marginTop: 15,
        height: 19,
        width: 109,
        color: '#101820',
        fontSize: 16,
        fontWeight: 'bold',
        letterSpacing: 0,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 9,
        paddingRight: 197
    },
    periods: {
        marginTop: 35
    },
    period: {
        borderBottom: '2px solid rgba(13,25,35,0.2)',
        minWidth: 315,
        paddingBottom: 8,
        paddingLeft: 0.5,
        marginTop: 14
    }
}))

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const monthNum = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

function IncomeDetails() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const currentDateRange = useSelector(state => state.driverUSReducer.getSettlementTimeList);

    const [fstStart, setFstStart] = useState('');
    const [sndStart, setSndStart] = useState('');
    const [trdStart, setTrdStart] = useState('');
    const [forStart, setForStart] = useState('');
    const [fstEnd, setFstEnd] = useState('');
    const [sndEnd, setSndEnd] = useState('');
    const [trdEnd, setTrdEnd] = useState('');
    const [forEnd, setForEnd] = useState('');
    const [fstStartTime, setFstStartTime] = useState('');
    const [sndStartTime, setSndStartTime] = useState('');
    const [trdStartTime, setTrdStartTime] = useState('');
    const [forStartTime, setForStartTime] = useState('');
    const [fstEndTime, setFstEndTime] = useState('');
    const [sndEndTime, setSndEndTime] = useState('');
    const [trdEndTime, setTrdEndTime] = useState('');
    const [forEndTime, setForEndTime] = useState('');

    function getFormerDates(timeCurr) {
        const yearCurr = Number(timeCurr.substring(0,4));
        const monthCurr = Number(timeCurr.substring(4,6)) - 1;
        const dateCurr = Number(timeCurr.substring(6));
        let year = yearCurr;
        let month = monthCurr;
        let date = dateCurr;
        if(dateCurr === 1) {
            date = 16;
            if(monthCurr === 0) {
                month = months.length - 1;
                year = yearCurr - 1;
            } else {
                month = monthCurr - 1;
            }
        } else if(dateCurr <= 16) {
            date = 1;
        } else {
            date = 16;
        }
        const timeStr = months[month] + ' ' + date;
        if(date < 10) {
            date = '0' + date;
        }
        const timeParam = year + '' + monthNum[month] + date;
        return [timeStr, timeParam];
    }
    function handleStatement(period, key) {
        if (process.env.REACT_APP_COUNTRY === 'CA') {
            if (period === 1) {
                window.localStorage.setItem('current-start', fstStart);
                window.localStorage.setItem('current-end', fstEnd);
                window.localStorage.setItem('current-start-time', fstStartTime);
                window.localStorage.setItem('current-end-time', fstEndTime);
            } else if (period === 2) {
                window.localStorage.setItem('current-start', sndStart);
                window.localStorage.setItem('current-end', sndEnd);
                window.localStorage.setItem('current-start-time', sndStartTime);
                window.localStorage.setItem('current-end-time', sndEndTime);
            } else if (period === 3) {
                window.localStorage.setItem('current-start', trdStart);
                window.localStorage.setItem('current-end', trdEnd);
                window.localStorage.setItem('current-start-time', trdStartTime);
                window.localStorage.setItem('current-end-time', trdEndTime);
            } else if (period === 4) {
                window.localStorage.setItem('current-start', forStart);
                window.localStorage.setItem('current-end', forEnd);
                window.localStorage.setItem('current-start-time', forStartTime);
                window.localStorage.setItem('current-end-time', forEndTime);
            }
            history.push('/driverMobile/incomeStatement');
        } else {
            viewDetailsUS(key);
        }
    }

    async function viewDetailsUS(date) {
        try {
            let driverId = window.localStorage.getItem('id');
            let subjectId = window.localStorage.getItem('subject_id');
            window.localStorage.setItem('current-start-end', date);
            let promises = {};
            await Promise.all(currentDateRange[date].days.map(async (day, key) => {
                await dispatch(getPaymentPeriod(subjectId, day.start, day.end))
                    .then((res) => {
                        promises[day.date] = res;
                    })
            }))
            await dispatch(handleDetails(sortKeys(promises)));
            await history.push('/driverMobile/deliveryStatements');
        } catch {
            dispatch(showAlertSnackbar({ message: t('contact_it'), type: 'error' }))
        } finally { }
    }


    useEffect(() => {
        setFstStart(window.localStorage.getItem('lastStart'));
        setFstEnd(window.localStorage.getItem('lastEnd'));
        setFstStartTime(window.localStorage.getItem('lastStartTime'));
        setFstEndTime(window.localStorage.getItem('lastEndTime'));
        setSndEnd(window.localStorage.getItem('lastStart'));
        setSndEndTime(window.localStorage.getItem('lastStartTime'));
    }, [window.localStorage])
    useEffect(() => {
        if(sndEndTime) {
            let third = getFormerDates(sndEndTime);
            setSndStart(third[0]);
            setSndStartTime(third[1]);
            setTrdEnd(third[0]);
            setTrdEndTime(third[1]);
            let fourth = getFormerDates(third[1]);
            setTrdStart(fourth[0]);
            setTrdStartTime(fourth[1]);
            setForEnd(fourth[0]);
            setForEndTime(fourth[1]);
            let fifth = getFormerDates(fourth[1]);
            setForStart(fifth[0]);
            setForStartTime(fifth[1]);
        }
    }, [sndEndTime])

    return (
        <div className={classes.root}>
            <div onClick={() => history.goBack()}>
                <KeyboardBackspaceIcon fontSize='large' />
            </div>
            {
                process.env.REACT_APP_COUNTRY === 'CA' ?
                    <div>
                        <div className={classes.title}>
                            Income Statements
                        </div>
                        <div className={classes.subTitle}>
                            Last 60 days
                        </div>
                        <div className={classes.periods}>
                            <div className={classes.period} onClick={() => handleStatement(1)}>{fstStart} - {fstEnd}</div>
                            <div className={classes.period} onClick={() => handleStatement(2)}>{sndStart} - {sndEnd}</div>
                            <div className={classes.period} onClick={() => handleStatement(3)}>{trdStart} - {trdEnd}</div>
                            <div className={classes.period} onClick={() => handleStatement(4)}>{forStart} - {forEnd}</div>
                        </div>
                    </div>
                    :
                    <div>
                        <div className={classes.title}>
                            Settlement History
                        </div>
                        <div className={classes.subTitle}>
                            Last 28 days
                        </div>
                        <div className={classes.periods}>
                            {
                                Object.keys(currentDateRange).map((key, index) => (
                                    <div>
                                        {
                                            index !== 0 &&
                                            <div className={classes.period} onClick={() => handleStatement(4, key)}>{key}</div>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
            }
        </div>
    )
}
export default IncomeDetails;