import { Button, InputAdornment, makeStyles, TextField, withStyles } from "@material-ui/core";
import HelpIcon from '@mui/icons-material/Help';
import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { editDriver } from "../../actions/t4aActions";
import { showAlertSnackbar } from "../../reducers/uiSlice";
import FullscreenLoading from "../FullscreenLoading";

const FixedTextField = withStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-input': {
            padding: '10px !important',
        },
        width: 360,
        marginTop: 8
    },
}))(TextField);
const ButtonEdit = withStyles((theme) => ({
    root: {
        padding: '13px 14px 12px 16px',
        borderRadius: 4,
        border: 'solid 2px #ff9d00',
        backgroundColor: '#fff',
        '& .MuiButton-label': {
            fontSize: 14,
            fontWeight: 500,
            textAlign: 'center',
            color: '#ff9d00'
        },
        '&:hover': {
            background: "#fff",
        },
    }
}))(Button);
const ButtonConfirm = withStyles((theme) => ({
    root: {
        padding: '8px 30px 10px 32px',
        borderRadius: 4,
        backgroundColor: '#f68d2e',
        '& .MuiButton-label': {
            fontSize: 14,
            fontWeight: '500',
            textAlign: 'center',
            color: '#101820'
        },
        '&:hover': {
            background: "#F68D2E",
        },
    }
}))(Button);
const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 17,
        fontWeight: 'bold',
        color: '#262626',
        marginTop: 34,
        marginLeft: 20
    },
    description: {
        fontSize: 14,
        color: '#262626',
        marginTop: 23,
        marginLeft: 20
    },
    descriptionInfo: {
        fontWeight: 'bold'
    },
    info: {
        backgroundColor: '#fff4ea',
        marginTop: 18,
        paddingTop: 15,
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 37
    },
    infoTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        color: '#262626'
    },
    infoName: {
        fontSize: 14,
        color: '#636463',
        marginTop: 17,
        width: 305,
        fontWeight: 'normal'
    },
    buttonStyle: {
        width: '100%', 
        textAlign: 'center', 
        marginTop: 24
    },
    confirm: {
        width: 250,
        marginTop: 45,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    buttonGroup: {
        width: '100%', 
        textAlign: 'center', 
        marginTop: 24,
        display: 'flex',
        justifyContent: 'space-between'
    }
}))

function T4A() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const driverInfo = useSelector(state => state.t4a.searchDriver);

    const [descriptionOpen, setDescriptionOpen] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [address, setAddress] = useState('');
    const [insurance, setInsurance] = useState('');
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(false);
    const [id, setId]=useState();

    const handleDescriptionClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        } 
        setDescriptionOpen(false);
    };

    function init() {
        setLastName(driverInfo.last_name);
        setFirstName(driverInfo.first_name);
        setAddress(driverInfo.address);
        setInsurance(driverInfo.sin);
        setAmount(driverInfo.amount);
        setId(driverInfo.id);
    }
    function handleEdit() {
        setLoading(true);
        let params = {
            last_name: lastName,
            first_name: firstName,
            address: address,
            sin: insurance
        };
        dispatch(editDriver(id, params))
        .then(() => {
            setLoading(false);
            setDisabled(true);
            dispatch(showAlertSnackbar({message: 'Upload information successfully!', type: 'success'}));
        })
        .catch(() => {
            setLoading(false);
            dispatch(showAlertSnackbar({message: 'Failed to upload information, please contact manager!', type: 'error'}));
        })
    }
    function handleConfirm() {
        setLoading(true);
        let params = {
            confirmed: 1
        };
        dispatch(editDriver(id, params))
        .then(() => {
            setLoading(false);
            history.push('t4a-download');
        })
        .catch(() => {
            setLoading(false);
            dispatch(showAlertSnackbar({message: 'Failed to upload confirm status, please contact manager!', type: 'error'}));
        })
    }

    useEffect(() => {
        setDisabled(true);
    }, [])
    useEffect(() => {
        if(driverInfo) {
            init();
        }
    }, [driverInfo])
    useEffect(() => {
        if(!window.localStorage.getItem('token')) {
            history.push('/t4a-login');
        }
    }, [window.localStorage])

    return (
        <div style={{paddingBottom: 40}}>
            <Snackbar open={descriptionOpen} autoHideDuration={10000} onClose={handleDescriptionClose}>
                <Alert onClose={handleDescriptionClose} variant="filled" severity="info" style={{color: 'white'}}>
                Contact your driver manager if there is any discrepancy in your total uniuni income.
                Contactez votre gestionnaire de chauffeur s'il y a une différence dans votre revenu uniuni total.
                </Alert>
            </Snackbar>
            <img 
                src='/images/group-copy.svg' 
                style={{
                    height: 42,
                    width: 178,
                    marginTop: 22,
                    marginLeft: 20
                }}
            />
            <div className={classes.title}>Review Your T4A / Révisez votre T4A</div>
            <div className={classes.description}>
                <div>
                    Payer's name / Nom du payeur: <span className={classes.descriptionInfo}>{driverInfo.company}</span>
                </div>
                <div>
                    Year / Annéé: <span className={classes.descriptionInfo}>{driverInfo.year}</span>
                </div>
            </div>
            <div
                className={classes.info}
            >
                <div className={classes.infoTitle}>
                    <div>
                        Recipient’s information
                    </div>
                    <div>
                        Informations sur le destinataire :
                    </div>
                </div>
                <div>
                    <div className={classes.infoName}>
                        Last name / Nom de famille
                    </div>
                    <FixedTextField 
                        variant="outlined"
                        value={lastName}
                        onChange={(e)=>setLastName(e.target.value)}
                        disabled={disabled}
                    />
                </div>
                <div>
                    <div className={classes.infoName}>
                        First name / Prénom
                    </div>
                    <FixedTextField 
                        variant="outlined"
                        value={firstName}
                        onChange={(e)=>setFirstName(e.target.value)}
                        disabled={disabled}
                    />
                </div>
                <div>
                    <div className={classes.infoName}>
                        Address / Adresse
                    </div>
                    <FixedTextField 
                        variant="outlined"
                        value={address}
                        onChange={(e)=>setAddress(e.target.value)}
                        disabled={disabled}
                    />
                </div>
                <div>
                    <div className={classes.infoName}>
                        Social insurance number / Numéro d'assurance sociale
                    </div>
                    <FixedTextField
                        variant="outlined"
                        value={insurance}
                        onChange={(e)=>setInsurance(e.target.value)}
                        disabled={disabled}
                    />
                </div>
                <div>
                    <div className={classes.infoName}>
                        Fees for services / Honoraires ou autres sommes pour services rendus
                    </div>
                    <FixedTextField 
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <HelpIcon 
                                    style={{color: '#8a8b8a'}}
                                    onClick={() => setDescriptionOpen(true)}
                                />
                            </InputAdornment>
                        }}
                        disabled
                        value={amount}
                    />
                </div>
                {
                    disabled &&
                    <div className={classes.buttonStyle}>
                        <ButtonEdit
                            style={{width: 194,height: 34}}
                            onClick={() => {
                                setDisabled(false);
                            }}
                        >
                            Edit / Éditer
                        </ButtonEdit>
                    </div>
                }
                {
                    !disabled &&
                    <div className={classes.buttonGroup}>
                        <ButtonEdit
                            style={{height: 34}}
                            onClick={() => {
                                init();
                                setDisabled(true);
                            }}
                        >
                            Cancel / Annuler
                        </ButtonEdit>
                        <ButtonConfirm
                            style={{height: 34}}
                            onClick={() => {
                                setDisabled(true);
                                handleEdit();
                            }}
                        >
                            Save / Sauver
                        </ButtonConfirm>
                    </div>
                }
            </div>
            <div className={classes.confirm}>
                <ButtonConfirm 
                    style={{ width: 250,height: 40}}
                    disabled={!disabled}
                    onClick={handleConfirm}
                >
                    Confirm / Confirmer
                </ButtonConfirm>
            </div>
            {/*<div style={{marginTop: 10, width: 300, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center'}}>Please confirm your information before February 24, 2022. / Veuillez confirmer vos informations avant le 24 février 2022.</div>*/}
            <div style={{marginTop: 10, width: 300, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center'}}>Please confirm your information before February 24, 2024. / Veuillez confirmer vos informations avant le 24 février 2024.</div>
            <FullscreenLoading open={loading}/>
        </div>
    )
}
export default T4A;